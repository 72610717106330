import { useQuery } from "@tanstack/react-query";
import API from "../Utility/API";
import { Entry } from "../Interfaces/Entry";

const useGetEntry = (entryId?: number) => {
  return useQuery({
    queryKey: ['entry', entryId],
    queryFn: async () => {
      return API.getEntry(entryId ?? 0);
    },
    enabled: !!entryId
  });
}

export default useGetEntry;