import React from 'react';

const CorrectionFeedback = () => {
  return (
    <div className='flex px-80 my-8'>
      <span className='mx-auto'>Thank you for providing feedback. We will look into the provided info and make the corrections as fit.</span>
    </div>
  );
}

export default CorrectionFeedback;