import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import API from '../Utility/API';
import useAuth from '../Utility/useAuth';
import { NotificationTypes } from '../Interfaces/Notification';
import APIError from '../Interfaces/APIError';
import { useAppStore } from '../Hooks/useAppStore';
import Modal from './Modal';

interface ILoginProps {
  state?: any;
}

function Login(props: ILoginProps) {
    const [ email, setEmail ] = useState(localStorage.getItem('remember-email') ?? '');
    const [ password, setPassword ] = useState('');
    const [ rememberMe, setRememberMe ] = useState(!!localStorage.getItem('remember-email') ?? false);
    const [ forgotPassword, setForgotPassword ] = useState(false);
    const { mutate: resetPassword } = useMutation({
      mutationFn: API.resetPassword,
      onError: (error: APIError) => {
        useAppStore.setState({
          message: {
            message: error.message,
            altMessage: error.altMessage,
            type: NotificationTypes.Error
          }
        });
      }
    });
    const { login }: any = useAuth();

    const { mutate } = useMutation({
      mutationFn: API.authorize,
      onSuccess: (user) => {
        login(user);
      },
      onError: (error: APIError) => {
        useAppStore.setState({
          message: {
            message: error.message,
            altMessage: error.altMessage,
            type: NotificationTypes.Error
          }
        });
      }
    });

    const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if(e.key === 'Enter') {
        handleLogin();
      }
    }

    const handleLogin = () => {
        if(rememberMe) {
            localStorage.setItem('remember-email', email);
        }

        mutate({email, password});
    }

    return (
        <div className='py-8 lg:py-12 lg:px-40'>
            <form className='flex flex-col gap-4 mx-auto max-w-fit'>
                <h1 className='font-bold'>Login</h1>
                <div className='flex flex-col'>
                    <label htmlFor='email'>Email</label>
                    <input 
                        type='email'
                        id='email'
                        className='p-2 border outline-none ring-primary focus:ring-2 rounded-lg'
                        value={email}
                        onKeyDown={onEnter}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }} 
                        placeholder="Email"
                    />
                </div>
                <div className='flex flex-col'>
                    <label htmlFor='password'>Password</label>
                    <input 
                        id='password'
                        type='password'
                        className='p-2 border outline-none ring-primary focus:ring-2 rounded-lg'
                        value={password}
                        onKeyDown={onEnter}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(e.target.value);
                        }} 
                        placeholder="Password"
                    />
                </div>
                <div className='flex place-items-center gap-x-8'>
                    <label htmlFor='rememberMe'>Remember Email</label>
                    <input
                        type='checkbox'
                        id='rememberMe'
                        checked={rememberMe}
                        className='w-5 h-5 cursor-pointer accent-primary'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setRememberMe(e.target.checked);
                        }}
                    />
                </div>
                <div className='flex justify-between place-items-center gap-4'>
                    <span className='underline cursor-pointer' onClick={() => setForgotPassword(true)}>Forgot Password?</span>
                    <button
                        type='button'
                        className='button-submit'
                        onClick={handleLogin}
                    >Login</button>
                </div>
            </form>
            { forgotPassword &&
              <Modal
                title="Password Reset"
                onClose={() => setForgotPassword(false)}
                content={
                  <div className='flex flex-col gap-4 px-40 mt-8'>
                    <div className='flex flex-col'>
                      <label htmlFor="reset-email">Email</label>
                      <input id='reset-email' type='email' value={email} className='p-2 border outline-none ring-primary focus:ring-2 rounded-lg' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(e.target.value);
                      }} />
                    </div>
                    <div className='flex w-full justify-center'>
                      <button
                        type='button'
                        className='button-submit'
                        onClick={() => {
                          resetPassword(email, {
                            onSuccess: (data) => {
                              setForgotPassword(false);

                              useAppStore.setState({
                                message: {
                                  message: "Password Reset Submitted",
                                  altMessage: "Check your email for the reset link.",
                                  type: NotificationTypes.Success
                                }
                              });

                            },
                          });
                        }}
                      >Submit</button>
                    </div>
                  </div>
                }
              />
            }
        </div>
    );
}

export default Login;