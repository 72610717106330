import { useQuery } from "@tanstack/react-query";
import API, { IRelatedSearchArgs } from "../Utility/API";

const useSearchRelated = (params: IRelatedSearchArgs) => {
  return useQuery({
    queryKey: ['related-search', params.entryMetadataId, params.databaseId, params.page, params.resultsPerPage],
    queryFn: async () => {
      if(params.overrideType) {
        return API.searchRelatedName(params);
      } else {
        return API.searchRelatedEntries(params);
      }
    }
  });
}

export default useSearchRelated;