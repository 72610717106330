import React, { useState } from 'react';
import { EditorProvider, useCurrentEditor, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignCenter, faAlignLeft, faAlignRight } from '@fortawesome/free-solid-svg-icons';

const MenuBar = ({ onChange } : { onChange: any }) => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null
  }

  editor.on('update', onChange);

  return (
    <div className='flex gap-2 place-items-center'>
      <button
        type='button'
        onClick={() => {
          editor.chain().focus().toggleBold().run();
        }}
        className={'flex self-start py-1 px-2 h-9 justify-center border border-gray-200 rounded font-bold place-items-center ' + (editor.isActive('bold') ? 'bg-gray-200' : '')}
      >
        Bold
      </button>
      <button
        type='button'
        onClick={() => {
          editor.chain().focus().toggleItalic().run();
        }}
        className={'flex self-start py-1 px-2 h-9 justify-center border border-gray-200 rounded font-bold place-items-center ' + (editor.isActive('italic') ? 'bg-gray-200' : '')}
      >
        Italics
      </button>
      <button
        type='button'
        title='Align Left'
        onClick={() => {
          editor.chain().focus().setTextAlign('left').run();
        }}
        className={'flex self-start py-1 px-2 h-9 justify-center place-items-center border border-gray-200 rounded font-bold ' + (editor.isActive({textAlign: "left"}) ? 'bg-gray-200' : '')}
      >
        <FontAwesomeIcon icon={faAlignLeft} />
      </button>
      <button
        type='button'
        title='Align Center'
        onClick={() => {
          editor.chain().focus().setTextAlign('center').run();
        }}
        className={'flex self-start py-1 px-2 h-9 justify-center place-items-center border border-gray-200 rounded font-bold ' + (editor.isActive({textAlign: 'center'}) ? 'bg-gray-200' : '')}
      >
        <FontAwesomeIcon icon={faAlignCenter} />
      </button>
      <button
        type='button'
        title='Align Right'
        onClick={() => {
          editor.chain().focus().setTextAlign('right').run();
        }}
        className={'flex self-start py-1 px-2 h-9 justify-center place-items-center border border-gray-200 rounded font-bold ' + (editor.isActive({textAlign: 'right'}) ? 'bg-gray-200' : '')}
      >
        <FontAwesomeIcon icon={faAlignRight} />
      </button>
    </div>
  )
}

const extensions = [
  StarterKit,
  TextAlign.configure({
    types: ['heading', 'paragraph']
  })
];

interface ITextEditorProps {
  content: string;
  id: string;
  onChange: Function;
}

const TextEditor = (props: ITextEditorProps) => {
  return (
    <EditorProvider editorProps={{
      attributes: {
        id: props.id,
        class: 'p-1 border border-gray-200 bg-white outline-none rounded ring-primary focus:ring-2 min-h-20'
      }
    }} slotBefore={<MenuBar onChange={props.onChange} />} extensions={extensions} content={props.content}>&nbsp;</EditorProvider>
  )
}

export default TextEditor;