import { useQuery } from "@tanstack/react-query";
import API from "../Utility/API";

const useGetEntryMetadata = (entryId: number) => {
  return useQuery({
    queryKey: ['metadata', entryId],
    queryFn: async () => {
      return API.getEntryMetadata(entryId);
    },
    enabled: !!entryId
  });
}

export default useGetEntryMetadata;