import { useQuery } from "@tanstack/react-query";
import API from "../Utility/API";

const useGetDatabaseMetadata = (databaseId?: number) => {
  return useQuery({
    queryKey: ['database-metadata', databaseId],
    queryFn: async () => {
      return API.getDatabaseMetadata(databaseId ?? 0);
    },
    enabled: !!databaseId,
    retry: false
  });
}

export default useGetDatabaseMetadata;