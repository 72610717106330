import React from "react";
import { useQuery } from "@tanstack/react-query";
import API from "../Utility/API";

const useGetUsers = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: API.getUsers
  });
}

export default useGetUsers;