import { useMutation, useQueryClient } from "@tanstack/react-query"
import API from "../Utility/API"


const useDeleteEntry = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.deleteEntry,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['basic-search']
      });
      queryClient.invalidateQueries({
        queryKey: ['advanced-search']
      });
    }
  });
}

export default useDeleteEntry;