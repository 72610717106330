import React, { useState, useEffect } from 'react';
import { CorrectionFetchApiResponse, CorrectionResponse } from '../Interfaces/CorrectionFetchApiResponse';
import { useNavigate } from 'react-router-dom';
import { Entry } from '../Interfaces/Entry';
import { EntryCorrection } from '../Interfaces/EntryCorrection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { EntryValue } from '../Interfaces/EntryValue';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import DropdownButton from './DropdownButton';
import Util from '../Utility/Util';
import { EntryField } from '../Interfaces/EntryField';

interface ICorrectionResultProps {
  entries: Entry[];
  totalCount: number;
  currentPage: number;
  paginateKey: string;
  onChange: Function;
  refetchData?: Function;
}

const CorrectionResultSet = (props: ICorrectionResultProps) => {
  const navigate = useNavigate();
  const defaultResults = 9;
  const [ resultsPerPage, setResultsPerPage ] = useState<string|number>(localStorage.getItem(`paginate-${props.paginateKey}`) ?? defaultResults);
  const [ page, setPage ] = useState(1);
  let pageOptions = Util.getPageOptions();
  
  const getField = (entry: Entry, fieldId: number) => {
    return entry.database?.entryFields.find((i: {id: number}) => i.id === fieldId)?.name;
  }

  const getValue = (fieldId: number, entryId: number) => {
    let entry = props.entries.find((i: {id: number}) => i.id === entryId);
    if(!entry) return '';
    let value = entry.values.find((i: {entry_field_id: number}) => i.entry_field_id === fieldId)?.value;
    return value;
  }

  const groupValues = (values: EntryValue[]) => {
    let groupedValues = [];
    let hasData = true;
    let i = 0;
    while(hasData) {
      let field1 = values[i];
      let field2 = values[i + 1];
      if(field1 || field2) {
        groupedValues.push([field1, field2]);
      } else {
        hasData = false;
      }
      i = i + 2;
    }

    return groupedValues;
  }

  const handlePageChange = (selected: {[key:string]: number}) => {
    let page = selected.selected + 1;
    setPage(page);
    props.onChange(page, resultsPerPage);
  }

  const handleResultsPerPageChange = (key: string) => {
    setResultsPerPage(key);
    localStorage.setItem(`paginate-${props.paginateKey}`, key);
    // Do some query stuff
    props.onChange(page, key);
  }

  let lastPage = Math.ceil(props.totalCount / parseInt(resultsPerPage.toString()));
  let showingStart = parseInt(resultsPerPage.toString()) * (props.currentPage - 1);
  let showingEnd = showingStart + parseInt(resultsPerPage.toString());
  showingStart += 1;
  if(showingEnd > props.totalCount) {
    showingEnd = props.totalCount;
  }

  return (
    <>
      <div className={'grid px-4 gap-4 text-sm lg:text-lg max-w-fit mx-auto grid-cols-1 ' + (props.entries.length < 3 ? `lg:grid-cols-2` : 'lg:grid-cols-3')}>
        {props.entries && props.entries.length > 0 && props.entries.map((entry: Entry) => (
          <div key={entry.id} className='flex flex-col border-0 rounded-xl bg-white'>
            <div className='flex justify-between py-1 place-items-center bg-khaki border-0 rounded-t-xl w-full'>
                <span className='px-4 text-primary font-bold'>{entry?.type?.name}</span>
                <Link to={`/admin/corrections/entry/${entry.id}`}>
                  <FontAwesomeIcon className='flex px-2 cursor-pointer' icon={faArrowUpRightFromSquare} />
                </Link>
            </div>
            <div className='p-4'>
              <div className='grid grid-cols-2 gap-4 mb-4'>
                {entry.database && entry.database.entryFields.map((entryField: EntryField, key: number) => (
                  <div key={key} className='flex flex-col'>
                    <span className='text-primary font-bold'>{entryField.name}</span>
                    <span className='text-black'>{getValue(entryField.id, entry.id)}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='flex rounded-lg w-full relative justify-center px-8 lg:px-0 mt-4 lg:mt-8'>
        <span className='flex absolute text-sm w-full justify-center'>{`Showing ${showingStart} - ${showingEnd} of ${props.totalCount} Results`}</span>
        <div className='flex lg:gap-x-8 relative place-items-center mt-8 text-sm lg:text-lg'>
          <ReactPaginate
            className='flex w-full gap-x-2 place-items-center z-20'
            pageRangeDisplayed={-1}
            marginPagesDisplayed={-1}
            onPageChange={handlePageChange}
            forcePage={props.currentPage - 1}
            nextClassName='flex w-full justify-end'
            nextLabel={
              <button className={props.currentPage >= lastPage ? 'hidden ' : '' + 'flex place-items-center px-2 lg:px-4 gap-x-2 py-2 lg:ml-2'}>
                Next
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            }
            previousLabel={
              <button className={props.currentPage === 1 ? 'hidden ' : '' + 'flex place-items-center px-2 lg:px-4 gap-x-2 py-2 mr-2'}>
                <FontAwesomeIcon icon={faAngleLeft} />
                Previous
              </button>
            }
            pageCount={Math.ceil(props.totalCount / parseInt(resultsPerPage.toString()))}
          />
          <div className='min-w-fit'>
            <DropdownButton
              dropdownItems={pageOptions}
              buttonText={pageOptions[resultsPerPage]}
              onChange={handleResultsPerPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CorrectionResultSet;