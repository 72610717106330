import { create } from "zustand";

interface FieldTypeState {
  fieldTypes: {
    [key: number]: string;
  };
  set: (fieldTypes: any) => void;
}

export const useFieldTypeStore = create<FieldTypeState>((set) => ({
  fieldTypes: {},
  set: (fieldTypes) => {
    set((state) => ({ fieldTypes }))
  }
}));