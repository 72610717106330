import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../Utility/API";
import Image from "../Interfaces/Image";
import { Entry } from "../Interfaces/Entry";

const useUploadImage = (entryId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.uploadImage,
    onSuccess: (data: Image) => {
      queryClient.setQueryData(['entry', entryId], (oldData: Entry) => {
        oldData.images.push(data);
        return {...oldData};
      });
    }
  });
}

export default useUploadImage;