import React, { useState } from 'react';
import Modal from './Modal';

interface IConfirmModalProps {
  title: string;
  message: string;
  onApprove: Function;
  onDecline: Function;
}
const ConfirmModal = (props: IConfirmModalProps) => {
  return (
    <Modal
      title={props.title}
      onClose={props.onDecline}
      size={'max-w-fit'}
      content={
        <div className='flex flex-col gap-4 px-20 my-4'>
          <span>{props.message}</span>
          <div className='flex justify-center gap-16'>
            <button
              type='button'
              className='button-standard'
              onClick={() => {
                props.onDecline();
              }}
            >No</button>
            <button
              type='button'
              className='button-submit'
              onClick={() => {
                props.onApprove();
              }}
            >Yes</button>
          </div>
        </div>
      }
    />
  );
}

export default ConfirmModal;