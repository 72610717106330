export enum NotificationTypes {
  Success = 1,
  Error = 0,
  Warning = -1
};
export interface Notification {
  message: string;
  altMessage?: string;
  type?: NotificationTypes;
  time?: number;
}