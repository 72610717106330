import { create } from "zustand";
import { Notification } from "../Interfaces/Notification";

export interface AppState {
  message: Notification|null;
  notify: (message: Notification) => void;
  showMailingList: boolean;
  setShowMailingList: (show: boolean) => void;
  showNewSiteModal: boolean;
  setShowNewSiteModal: (show: boolean) => void;
}

export const useAppStore = create<AppState>((set) => ({
  message: null,
  notify: (message) => {
    set((state) => ({ message }))
  },
  showMailingList: false,
  setShowMailingList: (show: boolean) => set({showMailingList: show}),
  showNewSiteModal: false,
  setShowNewSiteModal: (show: boolean) => set({showNewSiteModal: show})
}));