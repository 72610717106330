import { faChartSimple, faGear, faHome, faPlus, faUserPen, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface IDashboardMenuProps {
  page: string;
}

const DashboardMenu = ({ page }: IDashboardMenuProps) => {

  const activeClass = 'border-0 shadow-xl bg-white';
  return (
    <div className='absolute left-0 top-20 lg:top-40 basis-1/6 mt-2'>
      <div className='flex flex-col w-fit'>
        <div className={'flex rounded-xl px-4 py-2 place-items-center ' + (page === 'dashboard' ? activeClass : '')}>
          <Link to="/admin/dashboard" className='p-2 flex justify-between gap-4 place-items-center'>
            <div className='flex justify-center place-items-center bg-white border rounded-lg w-8 h-8 shadow-xl'>
              <FontAwesomeIcon icon={faHome} />
            </div>
            Dashboard
          </Link>
        </div>
        <div className={'flex border-0 rounded-xl px-4 py-2 place-items-center ' + (page === 'create_entry' ? activeClass : '')}>
          <Link to="/admin/entry" className='p-2 flex justify-between gap-4 place-items-center'>
            <div className='flex justify-center place-items-center bg-white border rounded-lg w-8 h-8 shadow-xl'>
              <FontAwesomeIcon icon={faPlus} />
            </div>
            Create New Entry
          </Link>
        </div>
        <div className={'flex border-0 rounded-xl px-4 py-2 place-items-center ' + (page === 'corrections' ? activeClass : '')}>
          <Link to="/admin/corrections" className='p-2 flex justify-between gap-4 place-items-center'>
            <div className='flex justify-center place-items-center bg-white border rounded-lg w-8 h-8 shadow-xl'>
              <FontAwesomeIcon icon={faUserPen} />
            </div>
            Corrections
          </Link>
        </div>
        <div className={'flex border-0 rounded-xl px-4 py-2 place-items-center ' + (page === 'users' ? activeClass : '')}>
          <Link to="/admin/users" className='p-2 flex justify-between gap-4 place-items-center'>
            <div className='flex justify-center place-items-center bg-white border rounded-lg w-8 h-8 shadow-xl'>
              <FontAwesomeIcon icon={faUsers} />
            </div>
            Users
          </Link>
        </div>
        <div className='hidden flex border-0 rounded-xl px-4 py-2 place-items-center'>
          <Link to="/admin/databases" className='p-2 flex justify-between gap-4 place-items-center'>
            <div className='flex justify-center place-items-center bg-white border rounded-lg w-8 h-8 shadow-xl'>
              <FontAwesomeIcon icon={faChartSimple} />
            </div>
            Edit Databases
          </Link>
        </div>
        <div className={'flex border-0 rounded-xl px-4 py-2 place-items-center ' + (page === 'settings' ? activeClass : '')}>
          <Link to="/admin/settings" className='p-2 flex justify-between gap-4 place-items-center'>
            <div className='flex justify-center place-items-center bg-white border rounded-lg w-8 h-8 shadow-xl'>
              <FontAwesomeIcon icon={faGear} />
            </div>
            Settings
          </Link>
        </div>
      </div>
    </div>
  );
}

export default DashboardMenu;