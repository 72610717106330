import React, { MutableRefObject, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormEntryField from './FormEntryField';
import API from '../Utility/API';
import { ICorrectionValues } from '../Utility/API';
import Modal from './Modal';
import Util from '../Utility/Util';
import { useAppStore } from '../Hooks/useAppStore';
import { EntryField } from '../Interfaces/EntryField';
import { useFieldTypeStore } from '../Hooks/useFieldTypeStore';
import useGetEntry from '../Hooks/useGetEntry';
import ReCAPTCHA from './ReCAPTCHA';

interface ICorrectionProps {
  entryId?: number;
  onSubmit?: Function;
}

const Correction = (props: ICorrectionProps) => {
  const navigate = useNavigate();
  const ref: MutableRefObject<HTMLDivElement> = useRef<any>();
  const params = useParams<{ entryId: string }>();
  let entryId: number = Number(params.entryId);
  if(!entryId && props.entryId) {
    entryId = props.entryId;
  }
  const fieldTypes = useFieldTypeStore((state) => state.fieldTypes);
  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ comments, setComments ] = useState('');
  const [ showConfirmation, setShowConfirmation ] = useState(false);
  const [ entryFieldMap, setEntryFieldMap ] = useState<{[key: number]: string}>();
  const [ entryChanges, setEntryChanges ] = useState<ICorrectionValues[]>([]);
  const { data } = useGetEntry(entryId);

  const { mutate: saveCorrection } = useMutation({
    mutationFn: API.createCorrection,
    onSuccess: () => {
      useAppStore.setState({message: {
        message: "Thank you for your feedback!"
      }});
      Util.scrollToTop();
      if(props.onSubmit) {
        props.onSubmit();
      }
    }
  });

  const handleEntryChange = (e: React.ChangeEvent<HTMLInputElement>, fieldId: number|string) => {
    let newValue = e.target.value;
    let oldValue = data.values.find((i: { entry_field_id: number; }) => i.entry_field_id === fieldId)?.value;
    let newEntry = entryChanges?.find((i: { entry_field_id: number|string }) => i.entry_field_id === fieldId);
    if(!newEntry) {
      entryChanges?.push({
        entry_field_id: fieldId,
        old_entry_value: oldValue,
        new_entry_value: newValue
      });
    } else {
      newEntry.new_entry_value = newValue;
    }

    setEntryChanges([...entryChanges]);
  }

  const closeModal = () => {
    setShowConfirmation(false);
  }

  const getValue = (fieldId: number) => {
    let entryField: EntryField = data.database.entryFields.find((i: {id: number}) => i.id === fieldId);
    let newEntry = entryChanges?.find((i: { entry_field_id: number|string }) => i.entry_field_id === fieldId);
    let currentEntry = data.values.find((i: { entry_field_id: number; }) => i.entry_field_id === fieldId);
    let currentValue = currentEntry?.value;

    if(fieldTypes[entryField.field_type_id] === 'Date' && currentValue) {
      currentValue = new Date(currentValue).toISOString().split('T')[0];
    }
  
    return newEntry ? newEntry.new_entry_value : currentValue ?? '';
  }

  const saveCorrections = async (token: string) => {
    saveCorrection({
      entry_id: parseInt(entryId?.toString() ?? ''),
      correction_first_name: firstName,
      correction_last_name: lastName,
      correction_user_email: email,
      correction_comments: comments,
      values: entryChanges,
      token: token
    });
  }

  const handleSubmit = async (token: string) => {
    await saveCorrections(token);
  }

  return (
    <div ref={ref} className={'flex flex-col w-full gap-2 lg:gap-4 place-items-center text-sm md:text-base my-8 overflow-x-hidden' + (props.entryId ? '' : ' px-4 lg:px-96')}>
      <h1 className='font-bold text-center'>Entry Correction</h1>
      { data && data.database.entryFields.map((entryField: EntryField, key: number) => (
        <div key={key} className='grid grid-cols-2 gap-x-4 place-items-center pr-4'>
          <label htmlFor={`entry_field_${entryField.id}`} className='flex justify-end w-full'>{entryField.name}:</label>
          <FormEntryField
            fieldType={entryField.field_type_id}
            id={`entry_field_${entryField.id}`}
            className='p-2 text-xs lg:text-base rounded-lg outline-none ring-primary focus:ring-2 shadow-lg focus:shadow-2xl max-w-[125px] sm:max-w-full lg:max-w-fit'
            value={getValue(entryField.id)}
            placeholder={entryField.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleEntryChange(e, entryField.id);
            }}
            entryFieldId={entryField.id}
          />
        </div>
      ))}
      <div className='flex justify-between gap-8 justify-center'>
        <button
          type='button'
          className='button-standard'
          onClick={() => {
            setEntryChanges([]);
          }}
        >Reset</button>
        <button
          type='button'
          className='button-submit'
          onClick={() => {
            let scrollableEl = ref.current.closest('.modal-scrollable');
            Util.scrollToTop('smooth', scrollableEl);
            setShowConfirmation(true);
          }}
        >Submit</button>
      </div>
      {showConfirmation &&
        <Modal
          title='Confirm Changes?'
          onClose={closeModal}
          size='max-w-lg'
          content={
            <ReCAPTCHA action="correction" onSubmit={handleSubmit}>
              <div className='flex flex-col gap-4 my-8 place-items-center px-4 lg:px-16'>
                <span>Please enter your Name and Email.</span>
                <div className='flex flex-col'>
                  <label htmlFor='correction-first-name'>First Name:</label>
                  <input
                    id='correction-first-name'
                    className='p-2 text-xs lg:text-base border border-primary rounded outline-none ring-primary focus:ring-2 shadow-lg focus:shadow-2xl'
                    placeholder='First Name'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className='flex flex-col'>
                  <label htmlFor='correction-last-name'>Last Name:</label>
                  <input
                    id='correction-last-name'
                    className='p-2 text-xs lg:text-base border border-primary rounded outline-none ring-primary focus:ring-2 shadow-lg focus:shadow-2xl'
                    placeholder='Last Name'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
                <div className='flex flex-col'>
                  <label htmlFor='correction-email'>Email:</label>
                  <input
                    type='email'
                    id='correction-email'
                    className='p-2 text-xs lg:text-base border border-primary rounded outline-none ring-primary focus:ring-2 shadow-lg focus:shadow-2xl'
                    placeholder='Email'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className='flex flex-col'>
                  <label htmlFor='correction-comments'>Additional Comments:</label>
                  <textarea
                    id='correction-comments'
                    className='p-2 text-xs lg:text-base border border-primary rounded outline-none ring-primary focus:ring-2 shadow-lg focus:shadow-2xl'
                    placeholder='Additional Comments'
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setComments(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className='flex w-full justify-between text-xs lg:text-sm'>
                  <button
                    type='button'
                    className='button-standard'
                    onClick={closeModal}
                  >Cancel</button>
                  <button
                    className='button-submit'
                    data-sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                    data-action='correction'
                  >Confirm</button>
                </div>
              </div>
            </ReCAPTCHA>
          }
        />
      }
    </div>
  );
}

export default Correction;