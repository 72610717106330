import { useQuery } from "@tanstack/react-query"
import API from "../Utility/API"


const useGetMetadata = (params: {
  databaseId?: number,
  groupingId?: number
}) => {
  return useQuery({
    queryKey: ['getMetadata', params],
    queryFn: async () => API.getMetadata(params),
    enabled: !!(params.databaseId || params.groupingId)
  });
}

export default useGetMetadata;