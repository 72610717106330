import { useQuery } from "@tanstack/react-query";
import API from "../Utility/API";
import { useEffect } from "react";

const useGetAuth = (callback?: (response: any) => void) => {
  return useQuery({
    queryKey: ['isAuthed'],
    queryFn: async () => {
      const response = await API.isAuthed();
      if(callback) {
        return callback(response);
      }

      return response;
    },
    retryOnMount: true
  });
}

export default useGetAuth;