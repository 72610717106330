import React, { useState, useEffect, MutableRefObject, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import API from '../Utility/API';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import DropdownButton from './DropdownButton';
import SearchResultSet from './SearchResultSet';

import { SearchApiResponse } from '../Interfaces/SearchApiResponse';
import { EntryType } from '../Interfaces/EntryType';
import { DatabaseOption } from '../Interfaces/DatabaseOption';
import useGetDatabaseGroupings from '../Hooks/useGetDatabaseGroupings';

interface IBasicSearchProps {
  searchTerm?: string;
}

interface IFieldMap {
  [key: number]: any;
}

const BasicSearch = (props: IBasicSearchProps) => {
  const resultsRef: MutableRefObject<HTMLDivElement> = useRef<any>();
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [database, setDatabase] = useState('0');
  const [ databaseId, setDatabaseId ] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(localStorage.getItem(`paginate-basic-search`) ?? 9);
  const { data: databaseGroupings } = useGetDatabaseGroupings();
  const { data, isLoading, isFetching, refetch } = useQuery<SearchApiResponse>({
    queryKey: ['basic-search', searchTerm, databaseId, currentPage, resultsPerPage],
    queryFn: async () => {
      return API.basicSearch({
        searchTerm,
        database: databaseId,
        page: currentPage,
        resultsPerPage
      });
    },
    enabled: searchTerm !== ''
  });
  const [fieldMap, setFieldMap] = useState<IFieldMap>({});
  const [recordTypeMap, setRecordTypeMap] = useState<{[key: number]: string}>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === 'Enter') {
      setSearchTerm(inputValue);
    }
  }

  const handleButtonClick = async () => {
    setCurrentPage(1);
    setSearchTerm(inputValue);
  };

  const handlePageChange = async (page: number, resultsPerPage: number) => {
    setCurrentPage(page);
    setResultsPerPage(resultsPerPage);
  }

  function mapFields() {
    let fieldMapping: any = {};
    data?.databases.forEach((database) => {
      database.entryFields.forEach((field: any) => {
        if(!fieldMapping[database.id]) {
          fieldMapping[database.id] = {};
        }
        fieldMapping[database.id][field.id] = field.name;
      });
    })

    let recordMapping: any = {};
    data?.entryTypes.forEach((type: EntryType) => {
      recordMapping[type.id] = type.name;
    });

    setFieldMap(fieldMapping);
    setRecordTypeMap(recordMapping);
  }

  useEffect(() => {
    if(props.searchTerm) {
      setSearchTerm(props.searchTerm);
    }
  }, [props.searchTerm]);

  useEffect(() => {
    if(data?.databases.length) {
      mapFields();
    } else {
      setFieldMap({});
    }
  }, [data]);

  useEffect(() => {
    if(searchTerm) {
      refetch();
    }
  }, [currentPage, resultsPerPage])

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className='flex flex-col w-full place-items-center gap-y-4 lg:gap-y-8'>
      <div className='flex place-items-center relative sm:w-1/2'>
        <FontAwesomeIcon icon={faMagnifyingGlass} className='text-gray-300 absolute left-2' />
        <input placeholder='Search' className='p-4 pl-8 border border-gray-200 rounded-3xl w-full text-sm lg:text-lg shadow-lg outline-none ring-primary focus:ring-2' value={inputValue} onKeyDown={onKeyPress} onChange={handleInputChange}/>
      </div>
      { databaseGroupings && databaseGroupings.length > 0 &&
        <div ref={resultsRef} className='flex gap-x-2 lg:gap-x-8 w-full lg:w-1/2 px-2 lg:px-8 place-items-center z-20 text-sm lg:text-lg'>
          <DropdownButton
            buttonText={databaseGroupings.find((i: { id: number }) => i.id.toString() === database)?.name ?? 'All Databases'}
            onChange={(key: any) => {
              const dbId = key.match(/\d*$/)[0];
              setDatabaseId(dbId)
              setSearchTerm('');
              setDatabase(key);
            }}
            dropdownItems={{
              ...databaseGroupings.reduce((obj: any, item: DatabaseOption) => Object.assign(obj, { [item.id]: item.name}), {}),
              0: 'All Databases'
            }}
          />
          <button className='button-search' onClick={() => {handleButtonClick();}}>SEARCH</button>
        </div>
      }
      {data && !data.databases.length &&
        <span className='font-bold'>No Records Found. Make sure you try all variations of spelling for a name. Ex: Thomas - Tom - Tomas etc.</span>
      }
      {data && data.databases.length > 0 &&
        <SearchResultSet
          refetchData={refetch}
          recordCount={data.entries.length}
          fieldMapping={fieldMap}
          dataset={data}
          recordTypeMap={recordTypeMap}
          totalCount={data.totalCount ?? 0}
          paginateKey={'basic-search'}
          currentPage={currentPage}
          onChange={handlePageChange}
          scrollToRef={resultsRef}
        />
      }
      {!isLoading && isFetching &&
        <span className='flex w-full justify-center'>Loading...</span>
      }
    </div>
  );
};

export default BasicSearch;