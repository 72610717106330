import Modal from "./Modal";
import Images from './LinksModal/Images';

interface ILinksModal {
  onClose: () => void;
}

const images = Images;

const LinksModal = ({ onClose }: ILinksModal) => {

  return (
    <Modal
      title='Other Links'
      onClose={onClose}
      size="max-w-fit"
      content={
        <div className='flex flex-col lg:flex-row px-8 gap-8'>
          { images && images.map((image: { name: string, image: string, url: string }) => (
            <div key={image.name} className='flex flex-col gap-8 border rounded-lg min-w-fit pb-4'>
              <img src={image.image}/>
              <div className='px-4 flex flex-col place-items-center gap-4'>
                <h3 className='text-center font-bold'>{image.name}</h3>
                <a className='underline' target="_blank" href={image.url}>Website</a>
              </div>
            </div>
          ))}
        </div>
      }
    />
  );
}

export default LinksModal;