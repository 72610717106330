import { EntryField } from "../Interfaces/EntryField";
import Metadata from "../Interfaces/Metadata";
import MetadataAttribute from "../Interfaces/MetadataAttribute";
let Util = {
  groupEntryFields: (entryFields: EntryField[]) => {
    let tempData = [];
    let hasData = true;
    let i = 0;
    while(hasData && entryFields && entryFields.length) {
      let field1 = entryFields[i];
      let field2 = entryFields[i + 1];
      if(field1 || field2) {
        tempData.push([field1, field2]);
      } else {
        hasData = false;
      }
      i = i + 2;
    }

    return tempData;
  },
  scrollToTop: (type? : ScrollBehavior, el?: any, top?: HTMLElement | number, accountForHeader = true) => {
    let element = el ?? document.documentElement;
    let scrollTo = 0;
    if(typeof top === 'number') {
      scrollTo = top;
    } else {
      scrollTo = top?.offsetTop ?? 0;
    }

    if(scrollTo && accountForHeader) {
      const [ headerEl ] = document.getElementsByClassName('app-header');
      scrollTo -= headerEl.clientHeight;
    }

    element.scrollTo({
      top: scrollTo,
      left: 0,
      behavior: type
    });
  },
  copyToClipboard: (text: string) => {
    navigator.clipboard.writeText(text);
  },
  isMobile: () => {
    const width = window.innerWidth;
    return width <= 768;
  },
  getPageOptions: (): {[key: number|string]: string} => {
    return {
      9: '9 per page',
      18: '18 per page',
      27: '27 per page'
    };
  },
  convertToBase64: async (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    });
  },
  getEntryMetadataName: (attributes: MetadataAttribute[], metadata?: Metadata[]) => {
    const priorityNameFields = ['First Name', 'Last Name'];
    const fallBackNameFields = ['Name'];
    let names: string[] = [];
    let attrs: number[] = [];
    attributes.forEach((attribute: MetadataAttribute) => {
      if(priorityNameFields.includes(attribute.attribute)) {
        attrs.push(attribute.id);
      } else if(fallBackNameFields.includes(attribute.attribute)) {
        attrs.push(attribute.id);
      }
    });

    metadata?.forEach((mData: Metadata) => {
      if(attrs.includes(mData.attribute_id)) {
        names.push(mData.value);
      }
    });

    return names.length ? names.join(' ') : null;
  }
};
export default Util;