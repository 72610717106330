import { useMutation, useQueryClient } from "@tanstack/react-query"
import API, { IDeleteMetadataArgs } from "../Utility/API";
import EntryMetadata from "../Interfaces/EntryMetadata";


const useDeleteMetadata = (entryId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.deleteMetadata,
    onSuccess: (success: boolean, { entryMetadataId }: IDeleteMetadataArgs) => {
      queryClient.setQueryData(['metadata', entryId], (oldData: EntryMetadata[]) => {
        const oldIndex = oldData.findIndex((i: { id: number }) => i.id === entryMetadataId);
        delete(oldData[oldIndex]);
        oldData.splice(oldIndex, 1);
        return [...oldData];
      });
    }
  })
}

export default useDeleteMetadata;