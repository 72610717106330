import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Database } from "../Interfaces/Database";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState } from "react";
import DatabaseGrouping from "../Interfaces/DatabaseGrouping";

interface IGrouping {
  id: number;
  name: string;
  databases: Database[];
}

interface IAdvancedDropdown {
  groupings: IGrouping[];
  onChange: (database?: Database, grouping?: DatabaseGrouping) => void;
  selected?: Database|DatabaseGrouping;
  overrides: {
    [key: string]: string
  }
}

const AdvancedDropdown = ({ groupings, selected, onChange, overrides } : IAdvancedDropdown) => {
  const [showDropdown, setShowDropdown] = useState(false);

  function handleDropdownList() {
    setShowDropdown(!showDropdown);
  }

  const defaultListClass = 'absolute w-full z-30 max-h-[200px] lg:max-h-[300px] overflow-y-scroll';
  return (
    <div className='relative w-full col-span-2'>
      <button
        type='button'
        className={'border bg-gray-100 border-gray-300 flex place-items-center gap-x-2 lg:gap-x-4 justify-between p-2 lg:p-4 w-full'}
        onClick={handleDropdownList}
      >
        { selected?.name ?? 'Select A Database' }
        <FontAwesomeIcon icon={faAngleDown}/>
      </button>
      <ul
        className={showDropdown ? defaultListClass : defaultListClass + ' hidden'}
      >
        {groupings.map((grouping: IGrouping) => (
          <Fragment key={grouping.name}>
            <li
              className={'p-4 cursor-default font-bold bg-white'}
            >{grouping.name}</li>
            { !overrides[grouping.name] && grouping.databases.map((database: Database) => (
              <li
                key={database.id}
                className='p-4 cursor-pointer bg-gray-200 hover:bg-gray-300'
                onClick={() => {
                  setShowDropdown(false);
                  onChange(database);
                }}
              >{ database.name }</li>
            ))}
            { overrides[grouping.name] &&
              <li
                className='p-4 cursor-pointer bg-gray-200 hover:bg-gray-300'
                onClick={() => {
                  setShowDropdown(false);
                  onChange(undefined, grouping);
                }}
              >{ overrides[grouping.name] }</li>
            }
          </Fragment>
        ))}
      </ul>
    </div>     
  );
}

export default AdvancedDropdown;