import React, { MutableRefObject, useRef, useState } from 'react';
import { useFieldTypeStore } from '../Hooks/useFieldTypeStore';
import { AutoComplete, AutoCompleteChangeEvent } from 'primereact/autocomplete';
import useSearchAutofill from '../Hooks/useSearchAutofill';

interface IFormEntryProps {
    fieldType?: number;
    id?: string;
    onChange?: Function;
    value: string|number;
    className?: string;
    placeholder?: string;
    name?: string;
    readonly?: boolean;
    onKeyDown?: Function;
    disabled?: boolean;
    useDateRange?: boolean;
    dateMin?: string;
    entryFieldId?: number;
}

const fieldTypeMap : { [key: string]: string} = {
    String: 'text',
    Integer: 'number',
    Date: 'date',
    Autofill: 'autofill'
};

function FormEntryField(props: IFormEntryProps) {
    const autofillRef: MutableRefObject<any> = useRef<any>();
    const [ suggestions, setSuggestions ] = useState<String[]>([]);
    const fieldTypes = useFieldTypeStore((state) => state.fieldTypes);
    const { mutate: searchAutofill, isPending } = useSearchAutofill();
    let fieldType = 'text';
    if(props.fieldType && fieldTypes) {
      fieldType = fieldTypeMap[fieldTypes[props.fieldType]];
    }

    const handleAutofillSearch = () => {
      searchAutofill({
        entryFieldId: props.entryFieldId ?? 0,
        value: autofillRef.current.getInput().value
      }, {
        onSuccess: (response) => {
          setSuggestions(response);
        }
      })
    }

    return (
      <>
        { fieldType !== 'autofill' &&
          <input
              autoComplete='off'
              type={fieldType}
              id={props.id ?? ''}
              readOnly={props.readonly ?? false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if(props.onChange) {
                  if(props.useDateRange) {
                    let type = props?.id?.match(/_start$/) ? 'start' : 'end';
                    props.onChange(e, type);
                  } else {
                    props.onChange(e);
                  }
                }
              }}
              value={props.value}
              className={props.className ?? ''}
              placeholder={props.placeholder ?? ''}
              name={props.name}
              onKeyDown={(e: React.KeyboardEvent) => {
                props.onKeyDown && props.onKeyDown(e);
              }}
              disabled={props.disabled ?? false}
              min={props.dateMin ?? ''}
          />
        }
        { fieldType === 'autofill' &&
          <AutoComplete
            ref={autofillRef}
            value={props.value}
            onChange={(e: AutoCompleteChangeEvent) => {
              if(props.onChange) {
                props.onChange(e);
              }
            }}
            className='sm:max-w-full lg:max-w-auto'
            inputClassName={props.className + ' w-full'}
            placeholder={props.placeholder ?? ''}
            name={props.name}
            disabled={props.disabled ?? false}
            readOnly={props.readonly ?? false}
            id={props.id ?? ''}
            suggestions={suggestions}
            completeMethod={() => handleAutofillSearch()}
            panelClassName='z-60 bg-white p-2 [&>div>ul>li:hover]:bg-gray-100'
          />
        }
      </>
    );
}

export default FormEntryField;