import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import DashboardMenu from './DashboardMenu';
import useGetSettings from '../Hooks/useGetSettings';
import Setting from '../Interfaces/Setting';
import { BubbleMenu, Editor, EditorContent, EditorProvider, FloatingMenu, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextEditor from './TextEditor';
import useSaveSettings from '../Hooks/useSaveSettings';
import Util from '../Utility/Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAppStore } from '../Hooks/useAppStore';
import { NotificationTypes } from '../Interfaces/Notification';

const extensions = [
  StarterKit,
];

const Settings = () => {
  const defaultVal: Setting = {
    about_title: '',
    about_text: '',
    about_img: '',
    about_img2: '',
    home_text: ''
  };
  const fileRef : MutableRefObject<HTMLInputElement> = useRef<any>();
  const fileRef2 : MutableRefObject<HTMLInputElement> = useRef<any>();
  const { data: settings } = useGetSettings();
  const [ changes, setChanges ] = useState<Setting>(defaultVal);
  const { mutate: saveSettings } = useSaveSettings();

  useEffect(() => {
    if(settings) {
      setChanges({...settings});
    }
  }, [settings]);

  const handleAboutChange = ({ editor }: { editor: Editor }) => {
    // changes.about_text = JSON.stringify(editor.getJSON());
    changes.about_text = editor.getHTML();
    setChanges({...changes});
  }

  const handleHomeChange = ({ editor }: { editor: Editor }) => {
    // changes.about_text = JSON.stringify(editor.getJSON());
    changes.home_text = editor.getHTML();
    setChanges({...changes});
  }

  return (
    <>
      <DashboardMenu page='settings'/>
      <div className='flex flex-col px-80 my-8 min-h-72 gap-4 place-items-center'>
        { settings &&
          <>
            <h1 className='font-bold'>Settings</h1>
            <div className='text-base flex flex-col gap-4 w-full px-8'>
              <h3 className='font-bold border-b border-b-gray-300'>Links</h3>
              <div className='flex flex-col w-full'>
                <label htmlFor='links_email' className='text-left'>Email</label>
                <input type='email' id='links_email' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setChanges({...changes, email: e.target.value});
                }} value={changes.email ?? settings.email} className='p-2 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg w-1/2'/>
              </div>
              <div className='flex flex-col w-full'>
                <label htmlFor='links_address' className='text-left'>Address</label>
                <input id='links_address' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setChanges({...changes, address: e.target.value});
                }} value={changes.address ?? settings.address} className='p-2 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg w-1/2'/>
              </div>
              <div className='flex flex-col w-full'>
                <label htmlFor='links_address2' className='text-left'>Address 2</label>
                <input id='links_address2' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setChanges({...changes, address2: e.target.value});
                }} value={changes.address2 ?? settings.address2} className='p-2 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg w-1/2'/>
              </div>
              <div className='flex flex-col w-full'>
                <label htmlFor='links_facebook' className='text-left'>Facebook URL</label>
                <input id='links_facebook' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setChanges({...changes, facebook_url: e.target.value});
                }} value={changes.facebook_url ?? settings.facebook_url} className='p-2 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg w-1/2'/>
              </div>
              <div className='flex flex-col w-full hidden'>
                <label htmlFor='links_twitter' className='text-left'>Twitter URL</label>
                <input id='links_twitter' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setChanges({...changes, twitter_url: e.target.value});
                }} value={changes.twitter_url ?? settings.twitter_url} className='p-2 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg w-1/2'/>
              </div>
              <div className='flex flex-col w-full'>
                <label htmlFor='links_youtube' className='text-left'>YouTube URL</label>
                <input id='links_youtube' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setChanges({...changes, youtube_url: e.target.value});
                }} value={changes.youtube_url ?? settings.youtube_url} className='p-2 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg w-1/2'/>
              </div>
              <div className='flex flex-col w-full hidden'>
                <label htmlFor='links_instagram' className='text-left'>Instagram</label>
                <input id='links_instagram' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setChanges({...changes, instagram_url: e.target.value});
                }} value={changes.instagram_url ?? settings.instagram_url} className='p-2 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg w-1/2'/>
              </div>
              <div className='flex flex-col w-full hidden'>
                <label htmlFor='links_yelp' className='text-left'>Yelp</label>
                <input id='links_yelp' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setChanges({...changes, yelp_url: e.target.value});
                }} value={changes.yelp_url ?? settings.yelp_url} className='p-2 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg w-1/2'/>
              </div>
              <h3 className='font-bold border-b border-b-gray-300'>About</h3>
              <div className='flex flex-col w-full'>
                <label htmlFor='about_title' className='text-left'>About Title</label>
                <input id='about_title' onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setChanges({...changes, about_title: e.target.value});
                }} value={changes.about_title ?? settings.about_title} className='p-2 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg w-fit'/>
              </div>
              <div className='flex flex-col w-full'>
                <label htmlFor='about_img' className='text-left'>About Image</label>
                <button
                  type='button'
                  className='button-submit'
                  onClick={() => {
                    fileRef.current.click();
                  }}
                >Upload</button>
                <input
                  ref={fileRef}
                  type='file'
                  accept='image/*'
                  className='hidden'
                  onChange={ async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if(!e.target.files) return;
                    const file = e.target.files[0];
                    let base64 = await Util.convertToBase64(file);
                    setChanges({...changes, about_img: base64});
                  }}
                />
                { changes.about_img &&
                  <div className='flex relative w-fit'>
                    <img className='max-w-64 my-2' src={changes.about_img} />
                    <button
                      type='button'
                      className='flex place-items-center justify-center w-6 h-6 border rounded bg-white absolute top-2 right-2 text-sm'
                      onClick={() => {
                        setChanges({...changes, about_img: null});
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                }
              </div>
              <div className='flex flex-col w-full'>
                <label htmlFor='about_img2' className='text-left'>Alternate About Image</label>
                <button
                  type='button'
                  className='button-submit'
                  onClick={() => {
                    fileRef2.current.click();
                  }}
                >Upload</button>
                <input
                  ref={fileRef2}
                  type='file'
                  accept='image/*'
                  className='hidden'
                  onChange={ async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if(!e.target.files) return;
                    const file = e.target.files[0];
                    let base64 = await Util.convertToBase64(file);
                    setChanges({...changes, about_img2: base64});
                  }}
                />
                { changes.about_img2 &&
                  <div className='flex relative w-fit'>
                    <img className='max-w-64 my-2' src={changes.about_img2} />
                    <button
                      type='button'
                      className='flex place-items-center justify-center w-6 h-6 border rounded bg-white absolute top-2 right-2 text-sm'
                      onClick={() => {
                        setChanges({...changes, about_img2: null});
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                }
              </div>
              <div className='flex flex-col w-full'>
                <div className='flex gap-4 place-items-center'>
                  <label htmlFor='about_text' className='text-left self-end'>About Text</label>
                </div>
                <TextEditor id='about_text' content={settings.about_text} onChange={handleAboutChange}/>
              </div>
            </div>
            <div className='flex justify-between text-base'>
              <button
                type='button'
                className='button-submit'
                onClick={() => {
                  saveSettings(changes, {
                    onSuccess: () => {
                      useAppStore.setState({
                        message: {
                          message: "Successfully saved settings.",
                          type: NotificationTypes.Success,
                          time: 4000
                        }
                      });
                      Util.scrollToTop('smooth');
                    }
                  });
                }}
              >Save Changes</button>
            </div>
          </>
        }
      </div>
    </>
  );
}

export default Settings;