import { useQuery } from "@tanstack/react-query"
import API from "../Utility/API"


const useGetDatabaseGroupings = () => {
  return useQuery({
    queryKey: ['database-groupings'],
    queryFn: API.getDatabaseGroupings
  });
}

export default useGetDatabaseGroupings;