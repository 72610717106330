import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Util from '../Utility/Util';

interface IImagePreviewProps {
  image: string;
  onClose: Function;
}

const ImagePreview = (props: IImagePreviewProps) => {

  const ref : MutableRefObject<HTMLDivElement> = useRef<any>();
  const [ width, setWidth ] = useState(0);
  const [ height, setHeight ] = useState(0);

  const onClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const inPreview = target?.closest('.image');
    if(!inPreview) {
      props.onClose();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', onClick);
    });
    Util.scrollToTop();
    return () => {
      window.removeEventListener('click', onClick);
    }
  }, []);

  useEffect(() => {
    if(ref?.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.clientHeight);
    }
  }, [ref]);

  return (
    <div className="relative z-[100] image-preview-container" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div ref={ref} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      { props.image.toLowerCase().endsWith(".pdf") ? (
        <span className='flex justify-center absolute top-0'>
          <embed src={props.image} className='px-8' width={width} height={height} />
        </span>
      ) : (
        <div className="fixed flex justify-center top-8 bottom-0 z-[100] w-screen overflow-y-scroll">
          <div className='px-20'>
            <img className='image' src={props.image} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImagePreview;