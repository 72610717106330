import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import useGetUsers from '../Hooks/useGetUsers';
import User from '../Interfaces/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserPen } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import useSaveUser from '../Hooks/useSaveUser';
import API from '../Utility/API';
import DashboardMenu from './DashboardMenu';
import { useAppStore } from '../Hooks/useAppStore';
import { NotificationTypes } from '../Interfaces/Notification';

const Users = () => {
  const { data: users, isLoading } = useGetUsers();
  const [ selected, setSelected ] = useState<User|null>();
  const { mutate: saveUser } = useSaveUser(() => {
    setSelected(null);
  });
  const [ resendEmail, setResendEmail ] = useState(false);
  useQuery({
    queryKey: ['resendUserSetup', selected?.id],
    queryFn: async () => {
      setResendEmail(false);
      useAppStore.setState({
        message: {
          message: "Sent Setup Email.",
          type: NotificationTypes.Success,
          time: 4000
        }
      });
      setSelected(null);
      return API.resendUserSetup(selected?.id ?? 0)
    },
    enabled: resendEmail
  });

  return (
    <>
      <DashboardMenu page='users' />
      { isLoading &&
        <span >Loading...</span>
      }
      {!selected && users &&
        <div className='px-8 md:px-40 lg:px-80 my-8'>
          <h1 className='w-full text-center font-bold mb-4'>Users</h1>
          <div className='flex justify-end w-full mb-4'>
            <button
              type='button'
              className='flex gap-2 justify-between place-items-center py-2 px-4 border border-primary rounded-lg bg-white hover:bg-gray-100 hover:shadow-xl text-sm md:text-base lg:text-lg'
              onClick={() => setSelected({}) }
            >
              Create New User
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
          <div className='flex w-full justify-center'>
            <table className='border-separate border-spacing-y-0 cursor-default'>
              <thead className='font-bold text-center mb-4'>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody className='gap-x-8'>
                { users && users.map((user: User, key: number) => (
                  <tr key={key} className='text-center odd:bg-primary-light text-secondary h-12'>
                    <td className='p-2 lg:p-4'>{user.name}</td>
                    <td className='p-2 lg:p-4'>{user.email}</td>
                    <td className='p-2 lg:p-4'>
                      <div className='flex place-items-center justify-center bg-white border rounded-full w-9 h-9 cursor-pointer shadow-xl hover:bg-gray-100' onClick={() => {
                        setSelected(user);
                      }}>
                        <FontAwesomeIcon icon={faUserPen} className='text-sm'/>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      }
      { selected &&
        <Modal
          title={ selected?.id ? 'Edit User' : 'Create User'}
          onClose={() => { setSelected(null); }}
          content={
            <div className='flex flex-col w-full justify-center gap-y-4 lg:gap-y-8 place-items-center my-4 text-sm md:text-base lg:text-lg'>
              <div className='flex gap-x-4 lg:gap-x-8 place-items-center justify-start'>
                <label htmlFor='user-name'>Name:</label>
                <input
                  id='user-email'
                  className='p-2 lg:p-4 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg'
                  placeholder='Name'
                  value={selected?.name ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    selected.name = e.target.value;
                    setSelected({...selected});
                  }}
                />
              </div>
              <div className='flex gap-x-4 lg:gap-x-8 place-items-center justify-start'>
                <label htmlFor='user-email'>Email:</label>
                <input
                  id='user-email'
                  type='email'
                  className='p-2 lg:p-4 border border-gray-300 outline-none ring-primary focus:ring-2 rounded-lg'
                  placeholder='Email'
                  value={selected?.email ?? ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    selected.email = e.target.value;
                    setSelected({...selected});
                  }}
                />
              </div>
            </div>
          }
          footer={
            <div className='flex w-full justify-between text-sm lg:text-base'>
              <button
                type='button'
                className='button-standard'
                onClick={() => { setSelected(null); }}
              >Cancel</button>
              { !selected.active && selected.id &&
                <button
                  type='button'
                  className='inline-flex w-full justify-center place-items-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                  onClick={() => setResendEmail(true)}
                >Re-send User Setup Email</button>
              }
              <button
                type='button'
                className='button-submit'
                onClick={() => saveUser(selected)}
              >Save</button>
            </div>
          }
        />
      }
    </>
  );
}

export default Users;