import { useAppStore } from "../Hooks/useAppStore";
import EntryMetadata from "../Interfaces/EntryMetadata";
import Metadata from "../Interfaces/Metadata";
import { NotificationTypes } from "../Interfaces/Notification";
import Setting from "../Interfaces/Setting";
import User from "../Interfaces/User";
import APIError from "./APIError";

export interface IEntryValue {
  id: number;
  value: string;
};
export interface IEntry {
  database_id: number;
  entry_id?: number;
  entry_type_id: number;
  values: IEntryValue[]
}

export interface ICorrectionValues {
  entry_field_id: number|string;
  old_entry_value?: any;
  new_entry_value: any;
  status?: string;
  status_user_id?: number;
}

export interface ICorrection {
  entry_id: number;
  correction_first_name: string;
  correction_last_name: string;
  correction_user_email: string;
  correction_comments: string;
  values?: ICorrectionValues[];
  token: string;
}

export interface IMetadataSearchArgs {
  searchTerm: string;
  metadataTypeId: number;
  page?: number;
  resultsPerPage?: number;
  doSearch?: boolean;
}

export interface IDeleteMetadataArgs {
  entryId: number;
  entryMetadataId: number;
}

export interface IRelatedSearchArgs {
  entryMetadataId: number;
  databaseId: number;
  overrideType?: string;
  page?: number;
  resultsPerPage?: number;
}

interface IFindMetadataArgs {
  id?: number;
  metadataTypeId: number;
  metadata: Metadata[];
}

let headers = {
  'Content-Type': 'application/json'
};

let API = {
  getDatabases: async () => {
    return API.handleResponse(
      await fetch('/api/v1/databases')
    );
  },
  getDatabaseGroupings: async () => {
    return API.handleResponse(
      await fetch('/api/v1/database/groupings')
    );
  },
  getEntryTypes: () => {
    return fetch('/api/v1/entryTypes').then((res) => {
      return res.json();
    });
  },
  getFieldTypes: async () => {
    return await API.handleResponse(
      await fetch('/api/v1/fieldTypes')
    );
  },
  getEntryFields: async (databaseId: number, databaseGroupId?: number) => {
    if(databaseGroupId) {
      return await API.handleResponse(
        await fetch(`/api/v1/database/groupings/${databaseGroupId}/entryFields`)
      );
    } else {
      return await API.handleResponse(
        await fetch(`/api/v1/databases/${databaseId}/entryFields`)
      );
    }
  },
  basicSearch: async (searchOptions: {
    searchTerm: string,
    database: number,
    page: number,
    resultsPerPage: number|string
  }) => {
    const { searchTerm, database, page, resultsPerPage } = searchOptions;
    const dbParam = database != 0 ? `/${database}` : '';
    const response = await fetch(`/api/v1/search/basic/${searchTerm}${dbParam}?page=${page}&resultsPerPage=${resultsPerPage}`);
    return API.handleResponse(response);
  },
  getBasicSearchCount: async (searchTerm: string, database: number) => {
    const dbParam = database != 0 ? `/${database}` : '';
    return await API.handleResponse(
      await fetch(`/api/v1/search-count/basic/${searchTerm}${dbParam}`)
    );
  },
  advancedSearch: async (searchOptions: {
    searchValues: {[key: string]: string},
    currentPage: number,
    resultsPerPage: number|string,
    database: number|string,
    databaseGroup: number
  }) => {
    const { searchValues, database, currentPage, resultsPerPage, databaseGroup } = searchOptions;
    let queryArray: String[] = [];
    Object.keys(searchValues).forEach((key) => {
      let value = searchValues[key];
      queryArray.push(`${key}=${value}`);
    });
    queryArray.push(`page=${currentPage}`);
    queryArray.push(`resultsPerPage=${resultsPerPage}`);
    
    let response;
    if(databaseGroup) {
      response = await fetch(`/api/v1/search/groupings/${databaseGroup}?${queryArray.join('&')}`);
    } else {
      response = await fetch(`/api/v1/search/advanced/${database}?${queryArray.join('&')}`);
    }

    return API.handleResponse(response);
  },
  getEntry: async (entryId: string|number) => {
    return await API.handleResponse(await fetch(`/api/v1/entries/${entryId}`));
  },
  createEntry: async (data: IEntry) => {
    return await API.handleResponse(await fetch('/api/v1/entries', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    }));
  },
  getUsers: async () => {
    return await API.handleResponse(await fetch('/api/v1/users'));
  },
  getUserByToken: async (token: string) => {
    return await API.handleResponse(
      await fetch(`/api/v1/users/token/${token}`)
    );
  },
  updatePassword: async (user: {
    id: number,
    password: string,
    password2: string,
    token: string
  }) => {
    return await API.handleResponse(
      await fetch(`/api/v1/users/setup/${user.id}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(user)
      })
    );
  },
  resendUserSetup: async (userId: number) => {
    return await API.handleResponse(
      await fetch(`/api/v1/users/${userId}/resend-setup`)
    );
  },
  updateUser: async (user: User) => {
    return await API.handleResponse(
      await fetch(`/api/v1/users/${user.id}`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(user)
      })
    );
  },
  createUser: async (user: User) => {
    return await API.handleResponse(
      await fetch('/api/v1/users', {
        method: 'POST',
        headers,
        body: JSON.stringify(user)
      })
    );
  },
  upsertUser: async (user: User) => {
    if(user.id) return API.updateUser(user);

    return API.createUser(user);
  },
  uploadImage: async (params: { entryId: number, base64: string}) => {
    return await API.handleResponse(
      await fetch(`/api/v1/entries/${params.entryId}/image`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          image: params.base64
        })
      })
    );
  },
  deleteImage: async (params: { entryId: number, imageId: number}) => {
    return await API.handleResponse(
      await fetch(`/api/v1/entries/${params.entryId}/image/${params.imageId}`, {
        method: 'DELETE',
        headers
      })
    );
  },
  deleteEntry: async (entryId: number) => {
    return await API.handleResponse(
      await fetch(`/api/v1/entries/${entryId}`, {
        method: 'DELETE',
        headers
      })
    );
  },
  saveMetadata: async (params: { entryId: number, entryMetadata: EntryMetadata }) => {
    return await API.handleResponse(
      await fetch(`/api/v1/metadata/${params.entryId}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(params.entryMetadata)
      })
    );
  },
  deleteMetadata: async (params: IDeleteMetadataArgs) => {
    return await API.handleResponse(
      await fetch(`/api/v1/metadata/${params.entryId}/${params.entryMetadataId}`, {
        method: 'DELETE',
        headers
      })
    );
  },
  updateEntry: async (data: IEntry) => {
    if(!data.entry_id) {
      throw new Error("entry_id is required to update Entry.");
    }
    let response = await fetch(`/api/v1/entries/${data.entry_id}`, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(data)
    });

    return API.handleResponse(response);
  },
  getCorrections: async (searchOptions: {
    page: number,
    resultsPerPage: number|string
    status?: 'p'|'c'
  }) => {
    const { page, resultsPerPage, status } = searchOptions;
    const queryParams = API.buildQueryParams({
      page,
      resultsPerPage,
      status: status ?? 'p'
    });
    const response = await fetch(`/api/v1/corrections?${queryParams}`);

    if(!response.ok && response.status === 401) {
      // window.location.href = '/login';
    }
  
    return API.handleResponse(response);
  },
  getCorrectionsByEntryId: async (entryId: number) => {
    const response = await fetch(`/api/v1/corrections/entry/${entryId}`);

    if(!response.ok && response.status === 401) {
      // window.location.href = '/login';
    }

    return API.handleResponse(response);
  },
  rejectEntryCorrection: async (correction: {entryId: number, fieldId: number}) => {
    const { entryId, fieldId } = correction;
    const response = await fetch(`/api/v1/corrections/entry/${entryId}/reject/${fieldId}`, {
      method: 'POST',
      headers: headers
    });

    return await API.handleResponse(response);
  },
  acceptEntryCorrection: async (correction: {entryId: number, correctionId: number}) => {
    const { entryId, correctionId } = correction;
    const response = await fetch(`/api/v1/corrections/entry/${entryId}/accept/${correctionId}`, {
      method: 'POST',
      headers: headers
    });

    if(!response.ok && response.status === 401) {
      // window.location.href = '/login';
    }

    return API.handleResponse(response);
  },
  createCorrection: async (data: ICorrection) => {
    return API.handleResponse(await fetch('/api/v1/corrections', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    }));
  },
  getDatabaseMetadata: async (databaseId: number) => {
    return await API.handleResponse(
      await fetch(`/api/v1/metadata/database/${databaseId}`)
    );
  },
  getMetadata: async ({ databaseId, groupingId }: { databaseId?: number, groupingId?: number}) => {
    if(databaseId) return API.getDatabaseMetadata(databaseId);
    else return API.getDatabaseGroupingMetadata(groupingId);
  },
  getDatabaseGroupingMetadata: async (groupingId?: number) => {
    return await API.handleResponse(
      await fetch(`/api/v1/metadata/groupings/${groupingId}`)
    );
  },
  getEntryMetadata: async (entryId: number) => {
    return await API.handleResponse(
      await fetch(`/api/v1/metadata/${entryId}`)
    );
  },
  searchMetadata: async (params: IMetadataSearchArgs) => {
    const { searchTerm, metadataTypeId, page, resultsPerPage } = params;
    return await API.handleResponse(
      await fetch(`/api/v1/metadata/search/${searchTerm}/${metadataTypeId}?page=${page}&resultsPerPage=${resultsPerPage}`)
    );
  },
  findMetadata: async (entryMetadata: EntryMetadata) => {
    return await API.handleResponse(
      await fetch(`/api/v1/metadata/find/${entryMetadata.metadata_type_id}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(entryMetadata)
      })
    );
  },
  searchRelatedEntries: async (params: IRelatedSearchArgs) => {
    const { entryMetadataId, databaseId, page, resultsPerPage } = params;
    return await API.handleResponse(
      await fetch(`/api/v1/related/search/${entryMetadataId}/${databaseId}?page=${page}&resultsPerPage=${resultsPerPage}`)
    );
  },
  searchRelatedName: async (params: IRelatedSearchArgs) => {
    const { entryMetadataId, databaseId, page, resultsPerPage } = params;
    return await API.handleResponse(
      await fetch(`/api/v1/related/name/search/${entryMetadataId}/${databaseId}?page=${page}&resultsPerPage=${resultsPerPage}`)
    );
  },
  addEntryRelationship: async (params: {entryId: number; entryMetadataId: number; currentMetadataId?: number;}) => {
    const { entryId, entryMetadataId, currentMetadataId } = params;
    return await API.handleResponse(
      await fetch(`/api/v1/entries/${entryId}/relationships`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          entry_metadata_id: entryMetadataId,
          current_metadata_id: currentMetadataId
        })
      })
    );
  },
  submitFeedback: async (params: { name: string, email: string, message: string, token: string }) => {
    return await API.handleResponse(
      await fetch(`/api/v1/feedback`, {
        method: 'POST',
        headers,
        body: JSON.stringify(params)
      })
    );
  },
  searchAutofill: async (params: { value: string, entryFieldId: number }) => {
    return await API.handleResponse(
      await fetch(`/api/v1/search/entryField/${params.entryFieldId}/autofill`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          value: params.value
        })
      })
    );
  },
  authorize: async (user: {email: string, password: string}) => {
    const response = await fetch('/api/v1/login', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(user)
    });

    return API.handleResponse(response);
  },
  getSettings: async () => {
    return await API.handleResponse(
      await fetch('/api/v1/settings')
    );
  },
  updateSettings: async (settings: Setting) => {
    return await API.handleResponse(
      await fetch('/api/v1/settings', {
        method: 'POST',
        headers,
        body: JSON.stringify(settings)
      })
    );
  },
  resetPassword: async (email: string) => {
    return await API.handleResponse(
      await fetch('/api/v1/forgot-password', {
        method: 'POST',
        headers,
        body: JSON.stringify({email})
      })
    );
  },
  logout: async () => {
    const response = await fetch('/api/v1/logout');

    return API.handleResponse(response);
  },
  isAuthed: async () : Promise<boolean> => {
    const response = await fetch(`/api/v1/authed`).then((res) => {
      return res.json();
    }).then((data) => {
      return data.authenticated ?? false;
    });
    return response;
  },
  handleResponse: async (response: any) => {
    if(!response.ok) {
      if(response.status === 401) {
        useAppStore.setState({
          message: {
            message: "Logged Out.",
            type: NotificationTypes.Warning,
            time: 4000
          }
        });
        window.location.href = '/';
      }
      let error = await response.json();
      throw(new APIError(error));
    }
    let data = await response.json();
    if(data.data) {
      data.data.success = true;
    }
    return data.data ?? data;
  },
  buildQueryParams: (data: {[key: string]: string|number}) => {
    let queryParams: String[] = [];
    Object.keys(data).forEach((key) => {
      queryParams.push(`${key}=${data[key]}`);
    });

    return queryParams.join('&');
  }
};

export default API;