import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../Utility/API";
import User from "../Interfaces/User";
import { useAppStore } from "./useAppStore";
import APIError from "../Interfaces/APIError";
import { NotificationTypes } from "../Interfaces/Notification";

const useSaveUser = (onSuccess?: Function) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.upsertUser,
    onSuccess: (data) => {
      queryClient.setQueryData(['users'], (oldData: User[]) => {
        const foundKey = oldData.findIndex(user => user?.id === data.id);
        if(foundKey >= 0) oldData[foundKey] = data;
        else oldData.push(data);
        return [...oldData];
      });

      onSuccess && onSuccess();
    },
    onError: (error: APIError) => {
      useAppStore.setState({
        message: {
          message: error.message,
          altMessage: error.altMessage,
          type: NotificationTypes.Error
        }
      });
    }
  });
}

export default useSaveUser;