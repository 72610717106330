import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Util from '../Utility/Util';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    Util.scrollToTop();
  }, [pathname]);

  return null;
}

export default ScrollToTop;