import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../Utility/useAuth';
import useGetSettings from '../Hooks/useGetSettings';
import { faFacebook, faFacebookF, faYoutube, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import LinksModal from './LinksModal';

function Header() {
    const toggleRef = useRef();
    const { auth, logout }: any = useAuth();
    const [ toggleMenu, setToggleMenu ] = useState(false);
    const [ showLinks, setShowLinks ] = useState(false);
    const { data: settings } = useGetSettings();
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const displayLogin = Number(localStorage.getItem('displayLogin') ?? 0);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    }

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter') {
            navigate(`/search/${searchInput}`);
        }
    }

    const handleToggleMenu = (event: React.MouseEvent<SVGSVGElement>) => {
      setToggleMenu(!toggleMenu);
      if(!toggleMenu) {
        setTimeout(() => {
          document.addEventListener('click', function(e: MouseEvent) {
            if(event.target != e.target) {
              setToggleMenu(false);
            }
          }, {'once': true});
        });
      }
    }

    return (
      <div className='h-20 lg:h-40 app-header'>
        <div className='flex flex-col fixed z-[75] lg:z-0 lg:relative bg-gray-50 text-[#056839] w-full text-lg'>
          <div className='hidden lg:flex p-2 bg-[#056839] gap-x-2 justify-between'>
            {!!displayLogin &&
              <div className='hidden lg:flex'>
                { auth &&
                  <h2 className='text-white cursor-pointer text-base underline underline-offset-2' onClick={() => {
                    logout();
                  }}>Logout</h2>
                }
                { !auth &&
                  <Link className='text-white cursor-pointer text-base underline underline-offset-2' to='/login' state={{ from: location }}>Login</Link>
                }
              </div>
            }
            { settings &&
              <div className='hidden lg:flex text-white gap-4'>
                <Link to={settings.facebook_url} target="_blank" title="Facebook" className='flex self-center hover:shadow-lg gap-2'>
                  <FontAwesomeIcon icon={faFacebookF} className='text-3xl' />
                  <span>Facebook</span>
                </Link>
                <Link to={settings.youtube_url} target="_blank" title="YouTube" className='flex self-center hover:shadow-lg gap-2'>
                  <FontAwesomeIcon icon={faYoutubeSquare} className='text-3xl' />
                  <span>Youtube</span>
                </Link>
              </div>
            }
          </div>
          <div className='flex justify-between place-items-center px-4 w-full'>
              <Link to='/'>
                  <div className='flex place-items-center'>
                    { !settings || !settings.logo &&
                      <h1 className='text-5xl lg:text-8xl tracking-[-.4rem] lg:tracking-[-.75rem]'>BG</h1>
                    }
                    { settings && settings.logo &&
                      <img src={settings.logo} className='flex max-h-24 lg:max-h-28' />
                    }
                    <span className='hidden lg:flex flex-col text-base lg:text-xl !font-bold tracking-wide self-center print:flex print:flex-col'>
                        <h2 className='flex'>Browning Genealogy</h2>
                        <h2 className='flex -mt-2'>& Local History</h2>
                        <h4 className='flex text-xs italic font-light'>Researching Southwestern Indiana, Southeastern Illinois, and Northwestern Kentucky</h4>
                    </span>
                  </div>
              </Link>
              <div className='hidden lg:flex gap-x-4 place-items-center'>
                  { auth &&
                      <Link to='/admin/dashboard'>Dashboard</Link>
                  }
                  <Link to='/about'>About</Link>
                  <Link to='/contact'>Contact</Link>
                  <button
                    onClick={() => setShowLinks(true)}
                  >Other Links</button>
              </div>
              <div className='flex lg:hidden relative w-full justify-end print:hidden'>
                <FontAwesomeIcon icon={faBars} className='text-[#056839] text-2xl cursor-pointer' onClick={handleToggleMenu} />
                {toggleMenu &&
                  <ul className='flex flex-col absolute right-0 top-6 bg-gray-50 px-4 border rounded-lg divide-y divide-y-gray-200'>
                    { auth &&
                      <li>
                        <Link to='/admin/dashboard'>Dashboard</Link>
                      </li>
                    }
                    <li>
                      <Link to='/about'>About</Link>
                    </li>
                    <li>
                      <Link to='/contact'>Contact</Link>
                    </li>
                    <li>
                      <button
                        onClick={() => setShowLinks(true)}
                      >Other Links</button>
                    </li>
                    {!!displayLogin &&
                      <li>
                        { auth &&
                          <h2 onClick={() => {
                            logout();
                          }} className='cursor-pointer'>Logout</h2>
                        }
                        { !auth &&
                          <Link to='/login' state={{ from: location }}>Login</Link>
                        }
                      </li>                     
                    }
                  </ul>
                }
              </div>
            </div>
        </div>
        { showLinks &&
          <LinksModal onClose={() => setShowLinks(false)} />
        }
      </div>
    );
}

export default Header;