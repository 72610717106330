import { useMutation } from "@tanstack/react-query"
import API from "../Utility/API"
import APIError from "../Utility/APIError";
import { useAppStore } from "./useAppStore";
import { NotificationTypes } from "../Interfaces/Notification";


const useSubmitFeedback = () => {
  return useMutation({
    mutationFn: API.submitFeedback,
    onError: (error: APIError) => {
      useAppStore.setState({
        message: {
          message: error.message,
          altMessage: error.altMessage,
          time: 4000,
          type: NotificationTypes.Error
        }
      });
    }
  });
}

export default useSubmitFeedback;