import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import API from '../Utility/API';
import { CorrectionFetchApiResponse, CorrectionResponse } from '../Interfaces/CorrectionFetchApiResponse';
import { Database } from '../Interfaces/Database';
import CorrectionResultSet from './CorrectionResultSet';
import { Entry } from '../Interfaces/Entry';
import DashboardMenu from './DashboardMenu';

interface ICorrectionsProps {
}

const Corrections = (props: ICorrectionsProps) => {
  const [ currentPage, setCurrentPage ] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(localStorage.getItem(`paginate-corrections`) ?? 9);
  const [ databases, setDatabases ] = useState<Database[]>([]);
  const [ fieldMap, setFieldMap ] = useState<{[key: number]: string}>({});
  const [ typeMap, setTypeMap ] = useState<{[key: number]: string}>({});
  const [ mappedData, setMappedData ] = useState<Entry[]>([]);
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['corrections', currentPage, resultsPerPage],
    queryFn: async () => {
      return await API.getCorrections({
        page: currentPage,
        resultsPerPage
      });
    }
  });

  const handlePageChange = async (page: number, resultsPerPage: number) => {
    setCurrentPage(page);
    setResultsPerPage(resultsPerPage);
  }

  return (
    <>
      <DashboardMenu page='corrections'/>
      <div className='my-8 min-h-64'>
        <h1 className='text-center font-bold mb-8'>Pending Entry Corrections</h1>
        { isLoading &&
          <span className='flex w-full justify-center'>Loading...</span>
        }
        { data && data.entries && data.entries.length > 0 &&
          <CorrectionResultSet
            refetchData={refetch}
            entries={data.entries}
            totalCount={data.totalCount}
            paginateKey={'corrections'}
            onChange={handlePageChange}
            currentPage={currentPage}
          />
        }
        { !isLoading && (!data || !data.entries.length) &&
          <span className='flex w-full justify-center'>No Corrections Found</span>
        }
      </div>
    </>
  );
}

export default Corrections;