import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../Utility/API";
import APIError from "../Interfaces/APIError";

const useUpdateEntry = (entryId: number, onSuccess?: Function, onError?: Function) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.updateEntry,
    onSuccess: (data) => {
      queryClient.setQueryData(['entry', entryId], data);

      onSuccess && onSuccess();
    },
    onError: (error: APIError) => {
      onError && onError(error);
    }
  })
}

export default useUpdateEntry;