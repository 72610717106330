import React, { useEffect, useState } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import BasicSearch from './Components/BasicSearch';
import AdvancedSearch from './Components/AdvancedSearch';
import Entry from './Components/Entry';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Login from './Components/Login';
import API from './Utility/API';

// import './App.css';
import Dashboard from './Components/Dashboard';
import Correction from './Components/Correction';
import CorrectionFeedback from './Components/CorrectionFeedback';
import Corrections from './Components/Corrections';
import EntryCorrection from './Components/EntryCorrection';
import CreateEntry from './Components/CreateEntry';
import { Notification as NotificationInterface } from './Interfaces/Notification';
import Notification from './Components/Notification';
import RequireAuth from './Components/RequireAuth';
import { AuthProvider } from './Context/AuthProvider';
import ScrollToTop from './Components/ScrollToTop';
import Users from './Components/Users';
import { useAppStore } from './Hooks/useAppStore';
import { useFieldTypeStore } from './Hooks/useFieldTypeStore';
import AccountSetup from './Components/AccountSetup';
import { useQuery } from '@tanstack/react-query';
import FieldType from './Interfaces/FieldType';
import { useImageStore } from './Hooks/useImageStore';
import ImagePreview from './Components/ImagePreview';
import RelatedSearch from './Components/RelatedSearch';
import Settings from './Components/Settings';
import Contact from './Components/Contact';
import Modal from './Components/Modal';
import MailingList from './Components/MailingList';

function App() {
  const message = useAppStore((state) => state.message);
  const fieldTypes = useFieldTypeStore((state) => state.fieldTypes);
  const setFieldTypes = useFieldTypeStore((state) => state.set);
  const imagePreview = useImageStore((state) => state.image);
  const setImage = useImageStore((state) => state.setImage);
  const [ seenMailingList, setSeenMailingList ] = useState(JSON.parse(
    localStorage.getItem('seenMailingList') || 'false'
  ));
  const { showMailingList, setShowMailingList, showNewSiteModal, setShowNewSiteModal } = useAppStore();
  const [ visitedSite, setVisitedSite ] = useState(JSON.parse(
    localStorage.getItem('visitedSite') || 'false'
  ));
  useQuery({
    queryKey: ['field-types'],
    queryFn: async () => {
      let fieldTypes = await API.getFieldTypes();
      if(!fieldTypes.success) {
        return fieldTypes;
      }
      let fieldTypeMap: {[key: number]: string} = {};
      fieldTypes.forEach((fieldType: FieldType) => {
        fieldTypeMap[fieldType.id] = fieldType.name;
      });
    
      setFieldTypes(fieldTypeMap);
      return fieldTypeMap;
    }
  });

  return (
    <>
      <ScrollToTop />
      <AuthProvider>
        <div className='flex flex-col text-lg bg-gray-50 overflow-x-hidden lg:overflow-auto'>
          <nav>
            <Header />
          </nav>
          { message &&
            <Notification notification={message} />
          }
          { imagePreview &&
            <ImagePreview image={imagePreview} onClose={() => {
              setImage(null);
            }} />
          }
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/search/:searchTerm?" element={<Home />} />
            <Route path="/related/search" element={<RelatedSearch />} />
            <Route path="/login" element={<Login />} />
            <Route path="/correction/:entryId?" element={<Correction />} />
            <Route path="/correction/feedback" element={<CorrectionFeedback />} />
            <Route path="/entry/:entryId" element={<Entry />} />
            <Route path="/account-setup/:token" element={<AccountSetup />} />

            <Route element={<RequireAuth required={true} />} >
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/corrections" element={<Corrections />} />
              <Route path="/admin/corrections/entry/:entryId" element={<EntryCorrection />} />
              <Route path="/admin/entry" element={<CreateEntry />} />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/settings" element={<Settings />} />
            </Route>
          </Routes>
          <Footer/>
        </div>
        {(!seenMailingList || showMailingList) &&
          <></>
          /*
          <Modal
            title='Join our Mailing List'
            size='max-w-lg'
            content={<MailingList />}
            onClose={() => {
              localStorage.setItem('seenMailingList', '1');
              setShowMailingList(false)
              setSeenMailingList(true);
            }}
          />
          */
        }
        {(!visitedSite || showNewSiteModal) &&
          <Modal
            title='Welcome to our New Site!'
            size='max-w-7xl'
            content={
              <div className='flex flex-col px-4 gap-4 w-full'>
                Welcome to the new Browning Genealogy Website! If you have any questions, feel free to reach out to us at BrowningGenealogy@gmail.com
                <div className='flex justify-center'>
                  <iframe width="480" height="320" src="https://www.youtube.com/embed/5rL50i6jfEE" title="Youube Tutorial" allow="" allowFullScreen={true} ></iframe>
                </div>
              </div>
            }
            onClose={() => {
              localStorage.setItem('visitedSite', '1');
              setShowNewSiteModal(false);
              setVisitedSite(true);
            }}
          />
        }
      </AuthProvider>
    </>
  );
}

export default App;
