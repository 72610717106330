import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faChartSimple, faDatabase, faExclamation, faHome, faMagnifyingGlass, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import API from '../Utility/API';
import useAuth from '../Utility/useAuth';
import DashboardMenu from './DashboardMenu';

interface IDashboardProps {
}

const Dashboard = (props: IDashboardProps) => {
  const { data } = useQuery({
    queryKey: ['corrections'],
    queryFn: () => {
      return API.getCorrections({page: 1, resultsPerPage: 1});
    }
  });

  return (
    <div className='flex px-20 my-8 min-h-72'>
      <DashboardMenu page='dashboard'/>
    </div>
  )
}

export default Dashboard;