import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import API from '../Utility/API';
import { useAppStore } from '../Hooks/useAppStore';
import { NotificationTypes } from '../Interfaces/Notification';

const AccountSetup = () => {
  const navigate = useNavigate();
  const [ password, setPassword ] = useState('');
  const [ password2, setPassword2 ] = useState('');
  const { token } = useParams<{ token: string }>();
  const [ errorFields, setErrorFields ] = useState<String[]>([]);
  const notify = useAppStore((state) => state.notify);
  const { data: user, isError } = useQuery({
    queryKey: ['userByToken'],
    queryFn: async () => {
      return API.getUserByToken(token ?? '');
    },
    enabled: !!token
  });

  const { mutate: savePassword } = useMutation({
    mutationFn: API.updatePassword,
    onSuccess: () => navigate('/login')
  });

  const validate = () => {
    const minLength = 8;
    if(password.length < minLength) {
      notify({
        message: "Unable to update password",
        altMessage: "Password must have at least 8 characters.",
        type: NotificationTypes.Error,
        time: 5000
      });
      setErrorFields(['password']);
    } else if(password !== password2) {
      notify({
        message: "Unable to update password",
        altMessage: "Passwords do not match.",
        type: NotificationTypes.Error,
        time: 5000
      });
      setErrorFields(['password2']);
    } else {
      savePassword({
        id: user.id,
        password,
        password2,
        token: token ?? ''
      });
    }
  }

  return (
    <div className='flex flex-col justify-center w-full px-8 md:px-20 lg:px-80'>
      { isError &&
        <Navigate to='/'/>
      }
      { user && token &&
        <div className='grid grid-cols-2 gap-x-4 gap-y-8 my-8 text-sm lg:text-base place-items-center'>
          <h1 className='font-bold col-span-2 text-center text-base lg:text-lg'>Account Setup</h1>
          <label htmlFor='password1' className='font-bold justify-self-end'>Enter A Password:</label>
          <input
            id='password1'
            type='password'
            className={'py-2 px-4 border justify-self-start rounded-lg outline-none ring-primary focus:ring-2' + (errorFields.includes('password') ? ' ring-2 ring-red-600' : '')}
            autoComplete='new-password' 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            placeholder='Enter A Password'
            value={password}
          />
          <label htmlFor='password2' className='font-bold justify-self-end'>Re-enter Password:</label>
          <input
            id='password2'
            type='password'
            className={'py-2 px-4 border rounded-lg justify-self-start outline-none ring-primary focus:ring-2' + (errorFields.includes('password2') ? ' ring-2 ring-red-600' : '')}
            autoComplete='new-password' 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword2(e.target.value)}
            placeholder='Re-enter Password'
            value={password2}
          />
          <button
            type='button'
            className='button-submit col-span-2'
            onClick={validate}
          >
            Submit
          </button>
        </div>
      }
    </div>
  );
}

export default AccountSetup;