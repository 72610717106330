import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import API from '../Utility/API';
import FormEntryField from './FormEntryField';
import DropdownButton from './DropdownButton';
import { Database } from '../Interfaces/Database';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Util from '../Utility/Util';
import { EntryType } from '../Interfaces/EntryType';
import { NotificationTypes } from '../Interfaces/Notification';
import APIError from '../Interfaces/APIError';
import { useAppStore } from '../Hooks/useAppStore';
import DashboardMenu from './DashboardMenu';
import { Entry } from '../Interfaces/Entry';

interface ICreateEntryProps {
}

const CreateEntry = (props: ICreateEntryProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [ database, setDatabase ] = useState<number>();
  const [ entryType, setEntryType ] = useState<number>();
  const [ errorFields, setErrorFields ] = useState<{[key: number|string]: string}>({});
  const [ entryData, setEntryData ] = useState<{[key: number]: {id: number, value: string}}>({});
  const notify = useAppStore((state) => state.notify);
  const { data: databases } = useQuery({
    queryKey: ['databases'],
    queryFn: API.getDatabases
  });
  const { data: entryTypes } = useQuery({
    queryKey: ['entryTypes'],
    queryFn: () => {
      return API.getEntryTypes().then((data) => {
        return data.data;
      });
    }
  });
  const { data: groupedEntryFields } = useQuery({
    queryKey: ['entry-fields', database],
    queryFn: async () => {
      return await API.getEntryFields(database ?? -1).then((data) => {
        return Util.groupEntryFields(data);
      });
    },
    enabled: !!database
  });

  const { mutate, isPending } = useMutation({
    mutationFn: API.createEntry,
    onSuccess: (entry: Entry) => {
      notify({message: "Successfully Created Entry"})
      queryClient.setQueryData(['entry', entry.id], (oldData: Entry) => {
        return entry;
      });
      navigate(`/entry/${entry.id}`);
    },
    onError: (error: APIError) => {
      notify({
        message: error.message,
        altMessage: error.altMessage,
        time: 4000,
        type: NotificationTypes.Error
      });
      if(error.error?.fieldId) {
        setErrorFields({
          [error.error.fieldId]: error.error
        });
      }
      Util.scrollToTop('smooth');
    }
  })

  const handleEntryChange = (e: React.ChangeEvent<HTMLInputElement>, fieldId: number) => {
    delete errorFields[fieldId];
    const value = e.target.value;
    if(value) {
      entryData[fieldId] = {id: fieldId, value};
    } else {
      delete entryData[fieldId];
    }

    setErrorFields({...errorFields});
    setEntryData({...entryData});
  }

  const handleSave = async () => {
    if(!database || !entryType) {
      let message = "Entry Field is Required."
      setErrorFields({entry: message});
      notify({
        message,
        time: 4000,
        type: NotificationTypes.Error
      });
      Util.scrollToTop('smooth');
      return;
    } else if(!Object.keys(entryData).length) {
      notify({
        message: 'Please fill out the entry.',
        time: 4000,
        type: NotificationTypes.Error
      });
      Util.scrollToTop();
      return;
    }

    mutate({
      database_id: database,
      entry_type_id: entryType,
      values: Object.values(entryData)
    });
  }

  const reset = () => {
    setEntryData({});
    setErrorFields({});
  }

  return (
    <>
      <DashboardMenu page='create_entry'/>
      <div className='px-8 lg:px-96 my-8 min-h-64'>
        <div className='flex relative mb-8 justify-center'>
          <h1 className='flex font-bold mt-8 lg:mt-0'>Create an Entry</h1>
        </div>
        <div className='flex flex-col mx-auto lg:w-1/2 text-xs lg:text-lg'>
          { databases && databases.length > 0 &&
            <div className='flex w-full mx-auto justify-center'>
              <DropdownButton
                buttonText={databases.find((i: {id: number}) => i.id === database)?.name ?? 'Select A Database'}
                parentClass='relative justify-center w-full z-30'
                className='border relative bg-gray-100 border-gray-300 flex place-items-center gap-x-4 justify-between p-4 w-full z-30'
                onChange={(selectedId: number|string) => {
                  setDatabase(Number(selectedId));
                }}
                dropdownItems={
                  Object.fromEntries(databases.map((db: Database) => {
                    return [db.id, db.name]
                  }))
                }
              />
            </div>
          }
          { entryTypes &&
            <div className='flex w-full mx-auto justify-center mt-4'>
              <DropdownButton
                buttonText={entryTypes.find((i: {id: number}) => i.id === entryType)?.name ?? 'Select An Entry Type'}
                parentClass='relative justify-center w-full'
                className={'border relative bg-gray-100 border-gray-300 flex place-items-center gap-x-4 justify-between p-4 w-full' + (errorFields.entry ? ' ring-4 ring-red-600' : '')}
                onChange={(selectedId: number|string) => {
                  delete errorFields.entry;
                  setErrorFields({...errorFields});
                  setEntryType(Number(selectedId));
                }}
                dropdownItems={
                  Object.fromEntries(entryTypes.map((entryType: EntryType) => {
                    return [entryType.id, entryType.name]
                  }))
                }
              />
            </div>
          }
          { groupedEntryFields && groupedEntryFields.map((groupData, key: number) => (
            <div key={key} className='grid grid-cols-2 gap-x-4 my-2'>
              <label htmlFor={`entry_field_${groupData[0].id}`} className='font-bold text-secondary'>{groupData[0].name}</label>
              <label htmlFor={`entry_field_${groupData[1]?.id}`} className='font-bold text-secondary'>{groupData[1]?.name}</label>
              <FormEntryField
                id={`entry_field_${groupData[0].id}`}
                className={'p-2 border rounded-lg outline-none ring-primary focus:ring-2 shadow-lg focus:shadow-2xl' + (errorFields[groupData[0].id] ? ' ring-4 ring-red-600' : '')}
                placeholder={groupData[0].name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleEntryChange(e, groupData[0].id);
                }}
                fieldType={groupData[0].field_type_id}
                value={entryData[groupData[0].id]?.value ?? ''}
                entryFieldId={groupData[0].id}
              />
              {groupData[1] &&
                <FormEntryField
                  id={`entry_field_${groupData[1].id}`}
                  className={'p-2 border rounded-lg outline-none ring-primary focus:ring-2 shadow-lg focus:shadow-2xl' + (errorFields[groupData[1].id] ? ' ring-4 ring-red-600' : '')}
                  placeholder={groupData[1].name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleEntryChange(e, groupData[1].id)
                  }}
                  fieldType={groupData[1].field_type_id}
                  value={entryData[groupData[1].id]?.value ?? ''}
                  disabled={isPending}
                  entryFieldId={groupData[1].id}
                />
              }
            </div>
          ))}
          {groupedEntryFields && !!groupedEntryFields.length &&
            <div className='flex justify-between gap-x-8 w-full mt-4'>
              <button type='button' className='button-standard' onClick={reset} disabled={isPending}>
                Reset
              </button>
              <button type='button' className='button-submit' onClick={handleSave} disabled={isPending}>
                Save
              </button>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default CreateEntry;