import { Link } from "react-router-dom";
import useGetSettings from "../Hooks/useGetSettings";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const videos = [
  "https://www.youtube.com/embed/5rL50i6jfEE",
  "https://www.youtube.com/embed/UrxVsoolWi4",
  //"https://www.youtube.com/embed/v5qK8lONmic",
  "https://www.youtube.com/embed/ZQo83dP_ZGI",
  "https://www.youtube.com/embed/IrFxW9KODQ8",
  "https://www.youtube.com/embed/alpPMIqRCSg",
  "https://www.youtube.com/embed/mbSsvKtP2uc",
  "https://www.youtube.com/embed/PVT_gV20S1s"
];

interface IVideo {
  prev: string;
  current: string;
  next: string;
}

const VideoCarousel = () => {
  const { data: settings } = useGetSettings();
  const [ active, setActive ] = useState(0);
  const [ shownVideos, setShownVideos ] = useState<IVideo>({
    prev: videos[videos.length - 1],
    current: videos[0],
    next: videos[1]
  });

  const handlePrevious = () => {
    const newCurrentKey = (active === videos.length - 1) ? 0 : active + 1;
    const prevKey = (newCurrentKey === 0) ? videos.length - 1 : newCurrentKey - 1;
    const nextKey = (newCurrentKey === videos.length - 1) ? 0 : newCurrentKey + 1;
    const temp = {
      prev: videos[prevKey],
      current: videos[newCurrentKey],
      next: videos[nextKey]
    }
    setActive(newCurrentKey);
    setShownVideos({...temp});
  };

  const handleNext = () => {
    const newCurrentKey = (active === 0) ? videos.length - 1 : active - 1;
    const prevKey = (newCurrentKey === 0) ? videos.length - 1 : newCurrentKey - 1;
    const nextKey = (newCurrentKey === videos.length - 1) ? 0 : newCurrentKey + 1;
    const temp = {
      prev: videos[prevKey],
      current: videos[newCurrentKey],
      next: videos[nextKey]
    }
    setActive(newCurrentKey);
    setShownVideos({...temp});
  }

  return (
    <>
      { settings && settings.youtube_url &&
        <Link target="_blank" to={settings.youtube_url} className='text-xl underline underline-offset-2'>Find More Videos Like These on Our YouTube!</Link>
      }
      <div className='flex gap-4 relative'>
        <button
          type='button'
          className='text-2xl'
          onClick={handlePrevious}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className=''>
          <iframe title='Youtube Tutorial' width={480} height={320} allow="" allowFullScreen={true} src={shownVideos.prev}></iframe>
        </div>
        <div>
          <iframe title='Youtube Tutorial' width={480} height={320} allow="" allowFullScreen={true} src={shownVideos.current}></iframe>
        </div>
        <div className=''>
          <iframe title='Youtube Tutorial' width={480} height={320} allow="" allowFullScreen={true} src={shownVideos.next}></iframe>
        </div>
        <button
          type='button'
          className='text-2xl'
          onClick={handleNext}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </>
  )
}

export default VideoCarousel;