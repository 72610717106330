import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../Utility/API";
import EntryMetadata from "../Interfaces/EntryMetadata";
import APIError from "../Interfaces/APIError";
import { NotificationTypes } from "../Interfaces/Notification";
import { useAppStore } from "./useAppStore";

const useSaveMetadata = (entryId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.saveMetadata,
    onSuccess: (data: EntryMetadata) => {
      queryClient.setQueryData(['metadata', entryId], (oldData: EntryMetadata[]) => {
        const oldIndex = oldData.findIndex((i: { id: number }) => i.id === data.id);
        if(oldIndex >= 0) {
          oldData[oldIndex] = {...data};
        } else {
          oldData.push(data);
        }

        return [...oldData];
      });
    },
    onError: (error: APIError) => {
      useAppStore.setState({
        message: {
          message: error.message,
          altMessage: error.altMessage,
          time: 4000,
          type: NotificationTypes.Error
        }
      })
    }
  });
}

export default useSaveMetadata;