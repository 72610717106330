export default class APIError {
  message;
  altMessage;
  error;
  constructor(error: {message: string, error?: string, errors: any}) {
    this.message = error.message;
    this.altMessage = error.error;
    this.error = error;

    if(error.errors && !error.error) {
      this.altMessage = Object.values(error.errors).join("\n");
    }
  }
}