import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../Utility/API";
import { Entry } from "../Interfaces/Entry";

const useDeleteImage = (entryId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.deleteImage,
    onSuccess: (data: any, { imageId } : { imageId: number, entryId: number }) => {
      queryClient.setQueryData(['entry', entryId], (oldData: Entry) => {
        let oldIndex = oldData.images.findIndex((i : { id: number }) => i.id === imageId);
        delete(oldData.images[oldIndex]);
        oldData.images.splice(oldIndex, 1);
        return {...oldData};
      });
    }
  });
}

export default useDeleteImage;