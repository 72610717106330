import { useMutation } from "@tanstack/react-query"
import API from "../Utility/API"


const useSearchAutofill = () => {
  return useMutation({
    mutationFn: API.searchAutofill
  });
}

export default useSearchAutofill;