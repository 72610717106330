import { useRef, useState } from "react";
import Images from "./Carousel/Images";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const images = Images.map(
  value => ({value, sort: Math.random() })
).sort(
  (a, b) => a.sort - b.sort
).map(({value}) => value); // Only sort on refresh

const CarouselWrapper = () => {
  const nodeRef = useRef(null);
  const [ transition, setTransition ] = useState(false);
  const [ imageIdx, setImageIdx ] = useState(0);

  const duration = 400;

  const defaultStyle = {
    transition: `transform ${duration}ms ease-in-out`,
    transform: 'translateX(101%)'
  }

  const transitionStyles : {[key: string]: Object} = {
    entering: { transform: "translateX(101%)" },
    entered:  { transform: "translateX(0)" },
  };

  return (
    <Carousel
      showArrows={true}
      showThumbs={false}
      autoPlay={true}
      swipeable={true}
      infiniteLoop={true}
      interval={10000}
      className='w-full max-h-64 h-64 lg:max-h-[475px] lg:h-[475px] mb-4'
    >
      {images.map(({image, classes}, idx) => (
        <div key={idx} className='flex max-h-64 h-64 lg:h-[475px] lg:max-h-[475px] bg-black'>
          <img className={classes ?? 'object-cover'} src={image} />
        </div>
      ))}
    </Carousel>
  )
}

export default CarouselWrapper;