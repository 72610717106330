import { MutableRefObject, ReactElement, forwardRef } from "react";
import Header from "./Header";

interface IPrintEntry {
  children: ReactElement;
}

const PrintEntry = forwardRef<HTMLDivElement, IPrintEntry>(function PrintEntry(props, ref) {
  return (
    <div ref={ref} className='hidden flex-col print:flex font-print'>
      <Header />
      <div className='mt-8'>
        { props.children }
      </div>
    </div>
  )
})

export default PrintEntry;