import { useQuery } from "@tanstack/react-query";
import API from "../Utility/API";


const useGetSettings = () => {
  return useQuery({
    queryKey: ['settings'],
    queryFn: API.getSettings
  });
}

export default useGetSettings;