import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../Utility/API";
import APIError from "../Interfaces/APIError";
import { useAppStore } from "./useAppStore";
import { NotificationTypes } from "../Interfaces/Notification";

const useAddEntryRelationship = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: API.addEntryRelationship,
    onSuccess: (data, { entryId }) => {
      queryClient.invalidateQueries({
        queryKey: ['metadata', entryId]
      });
    },
    onError: (error: APIError) => {
      useAppStore.setState({
        message: {
          message: error.message,
          altMessage: error.altMessage,
          time: 4000,
          type: NotificationTypes.Error
        }
      });
    }
  });
}

export default useAddEntryRelationship;