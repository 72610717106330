import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

interface IDropdownProps {
  className?: string;
  dropdownItems: {
    [key: string|number]: string|number
  };
  buttonText: string;
  onChange: Function;
  parentClass?: string;
  childClass?: string;
}

function DropdownButton(props: IDropdownProps) {
  const [showDropdown, setShowDropdown] = useState(false);

  function handleDropdownList() {
    setShowDropdown(!showDropdown);
  }

  const defaultListClass = 'absolute w-full z-30 max-h-[200px] lg:max-h-[300px] overflow-y-scroll';
  return (
    <div className={ props.parentClass ?? 'relative w-full col-span-2' }>
      <button
        type='button'
        className={props.className ?? 'border bg-gray-100 border-gray-300 flex place-items-center gap-x-2 lg:gap-x-4 justify-between p-2 lg:p-4 w-full'}
        onClick={handleDropdownList}
      >
        {props.buttonText}
        <FontAwesomeIcon icon={faAngleDown}/>
      </button>
      <ul
        className={showDropdown ? defaultListClass : defaultListClass + ' hidden'}
      >
        {Object.keys(props.dropdownItems).map((key: string) => (
          <li
            key={key}
            className={props.childClass ?? 'p-4 cursor-pointer hover:bg-gray-300 bg-gray-200'}
            onClick={() => {
              handleDropdownList();
              props.onChange(key);
            }}
          >{props.dropdownItems[key]}</li>
        ))}
      </ul>
    </div>     
  );
}
export default DropdownButton;