import React, { useEffect, useState } from 'react';
import { Notification as NotificationInterface, NotificationTypes } from '../Interfaces/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation, faTriangleExclamation, faX } from '@fortawesome/free-solid-svg-icons';
import { useAppStore } from '../Hooks/useAppStore';
import { useParams } from 'react-router-dom';

interface INotificationProps {
  notification: NotificationInterface|null;
  // setNotification: Function;
}

const Notification = (props: INotificationProps) => {
  let timeout: ReturnType<typeof setTimeout>;
  const defaultTimeout = 10000;
  useEffect(() => {
    timeout = setTimeout(() => {
      useAppStore.setState({ message: null });
    }, props?.notification?.time ?? defaultTimeout);
  }, []);
  useEffect(() => () => {
    clearTimeout(timeout);
  }, []);

  const iconMap = {
    1: <FontAwesomeIcon className='text-green-500' icon={faCheck} />,
    "-1": <FontAwesomeIcon className='text-yellow-400' icon={faTriangleExclamation} />,
    0: <FontAwesomeIcon className='text-red-600' icon={faTriangleExclamation} />
  };

  const type = props?.notification?.type ?? NotificationTypes.Success;
  const icon = iconMap[type];

  const close = () => {
    useAppStore.setState({ message: null });
  }
  return (
    <div className='flex w-4/5 lg:w-full justify-center mx-auto relative'>
      <div className='fixed p-4 border rounded-xl shadow-2xl z-[100] w-full max-w-sm bg-white'>
        <div className='absolute right-1 top-0 cursor-pointer p-1' onClick={close}>
          <FontAwesomeIcon className='text-base' icon={faX} />
        </div>
        <div className='flex gap-4 place-items-center'>
          {icon}
          <div className='flex flex-col text-sm lg:text-base'>
            <span className='text-gray-900 font-semibold'>{props?.notification?.message}</span>
            <span className='text-gray-700'>{props?.notification?.altMessage ?? ''}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;