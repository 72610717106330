import { useQuery } from "@tanstack/react-query";
import API, { IMetadataSearchArgs } from "../Utility/API";

const useSearchMetadata = (params: IMetadataSearchArgs, callback?: Function) => {
  return useQuery({
    queryKey: ['metadata-search', params.searchTerm, params.metadataTypeId, params.page, params.resultsPerPage],
    queryFn: async () => {
      callback && callback();
      return API.searchMetadata(params);
    },
    enabled: !!(params.doSearch && params.searchTerm)
  });
}

export default useSearchMetadata;