import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../Utility/useAuth'; 
import { useQuery } from '@tanstack/react-query';
import API from '../Utility/API';
import useGetAuth from '../Hooks/useGetAuth';

const RequireAuth = ({ required, children }: any) => {
  const { auth, logout }: any = useAuth();
  const location = useLocation();
  useGetAuth((response) => {
    if(!response && !required) {
      logout();
    }
    return response;
  });

  if(!required) {
    return <Outlet />;
  }

  return auth?.id ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}

export default RequireAuth;