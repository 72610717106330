import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import useWindowSize from '../Hooks/useWindowSize';

interface IModalProps {
  content?: any;
  footer?: any;
  onClose: Function;
  title: string;
  size?: string;
}
function Modal(props: IModalProps) {

  const handleOnClose = () => {
    props.onClose();
  }

  return (
    createPortal(
      <div className="z-[75] modal-container" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="z-[75] fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-[75] w-screen overflow-y-auto modal-scrollable">
          <div className={"flex min-h-fit p-4 text-center sm:items-center sm:p-0 justify-center"}>
            <div className={`transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-11/12 md:w-full modal ${props.size ?? 'sm:max-w-lg'}`}>
              <div className="bg-gray-50 flex justify-between p-4 divide-y-gray-300">
                <h1 className="font-bold">{props.title}</h1>
                <button type='button' onClick={handleOnClose}>
                  <FontAwesomeIcon icon={faX} />
                </button>
              </div>
              {props.content}

              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                {props.footer}
              </div>
            </div>
          </div>
        </div>
      </div>
    , document.body)
  );
}

export default Modal;