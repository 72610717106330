import React, { ReactElement, useEffect, useState } from "react";

interface IReCAPTCHA {
  children: ReactElement;
  action: String;
  onSubmit: (token: string) => void;
}

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const ReCAPTCHA = ({ action, onSubmit, children }: IReCAPTCHA) => {

  useEffect(() => {
    if(window.grecaptcha) return;
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}`;
    // script.addEventListener("load", handleLoaded);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    }
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if(!window.grecaptcha) return;
    e.preventDefault();
    window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY, { action }).then((token: any) => {
      onSubmit(token)
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      { children }
    </form>
  );
}

export default ReCAPTCHA;