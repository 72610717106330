import { useQuery } from '@tanstack/react-query';
import React, { createContext, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../Utility/API';
import useGetAuth from '../Hooks/useGetAuth';

const AuthContext = createContext({});

export interface IAuth {
  auth: object;
  login: Function;
  logout: Function;
}

export const AuthProvider = ({ children }: any) => {
  const authLocal = JSON.parse(localStorage.getItem("user") ?? "null");
  const [ auth, setAuth ] = useState(authLocal);
  const navigate = useNavigate();
  useGetAuth((response) => {
    if(!response) {
      setAuth(null);
      localStorage.removeItem('user');
    }
    return response;
  })

  const login = async (data: any) => {
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("displayLogin", '1');
    setAuth(data);
    navigate("/admin/dashboard");
  }

  const logout = async () => {
    await API.logout();
    setAuth(null);
    localStorage.removeItem('user');
    navigate('/', { replace: true });
  }

  const value = useMemo(() => ({
    auth,
    login,
    logout
  }), [auth]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
};

export default AuthContext;